import { Card, Col, Row } from 'react-bootstrap';
import Placeholder from 'react-bootstrap/Placeholder';

function LoadingCards() {
  return (
    <div className="workspace-card-container">
      <Placeholder as={Card} className="workspace-card-placeholder" animation="wave">
        <Card.Header>
          <Card.Title>
            <Placeholder xs={8} />
          </Card.Title>
        </Card.Header>
        <Card.Body style={{ overflowY: 'auto' }}>
          <Row>
            <Col>
              <Placeholder xs={6} />
            </Col>
            <Col>
              <Placeholder xs={10} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Placeholder xs={4} />
            </Col>
            <Col>
              <Placeholder xs={3} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Placeholder xs={10} />
            </Col>
            <Col>
              <Placeholder xs={7} />
            </Col>
          </Row>
        </Card.Body>
      </Placeholder>
    </div>
  );
}

export default LoadingCards;
