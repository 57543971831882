import {
  createContext, useContext, useState, useMemo,
} from 'react';

export const ClientsContext = createContext({
  clients: null,
  setClients: () => {},
});

export function ClientsProvider({ children }) {
  const [clients, setClients] = useState(null);

  const value = useMemo(() => ({
    clients, setClients,
  }), [clients, setClients]);

  return (
    <ClientsContext.Provider value={value}>
      {children}
    </ClientsContext.Provider>
  );
}

export const useClients = () => useContext(ClientsContext);
