import {
  useCallback, useEffect, useState, useRef,
} from 'react';
import {
  Modal, Nav, Button, Form, Spinner, Col,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Stack from 'react-bootstrap/Stack';
import {
  CheckSquare, CloudDownload, CloudUpload, BoxArrowUp, Square,
} from 'react-bootstrap-icons';
import { calcExpectationData, defaultExpectationData } from '../../helper/expectations';
import toastService from '../../toast-service';
import WorkloadAPI from '../../workload-api';
import Error from '../error';
import { getCalculated } from '../../helper/activities';
import ActivityGroupsTable from '../evaluation/activity/activity-groups-table';
import InstructionsTable from '../evaluation/observation-instruction/instructions-table';
import ObservationsTable from '../evaluation/observation/observations-table';
import Loading from '../loading';
import ExpectationsTable from '../evaluation/expectation/expectations-table';
import ParetoTable from '../evaluation/pareto/pareto';
import EmployeeConfiguration from '../evaluation/workload/employee-configuration';
import Workload from '../evaluation/workload/employee-workload';
import SpinnerIcon from '../spinner-icon';
import BlueprintExport from './blueprint-export';
import Username from '../username';

const defaultEmployeeConfig = {
  daysPerFortnight: 0,
  hoursPerFortnight: 0,
  breakHoursPerDay: 0,
  availableFullTimeEmployees: 0,
  employeeAbsenceFactor: 0,
};

function TabHeader(props) {
  const { children } = props;
  return (
    <Nav.Item>
      <Nav.Link {...props}>
        {children}
      </Nav.Link>
    </Nav.Item>
  );
}

function Warning({
  show, title, body, cancel, onCancel, confirm, onConfirm,
}) {
  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancel}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditBluePrint() {
  const { blueprintId } = useParams();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [validated, setValidated] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [warning, setWarning] = useState(null);
  const [saving, setSaving] = useState(false);
  const [approving, setApproving] = useState(false);

  const [approvedOn, setApprovedOn] = useState(null);
  const [approvedBy, setApprovedBy] = useState(null);

  const [showExportModal, setShowExportModal] = useState(false);

  const [name, setName] = useState('');
  const nameChanged = useCallback((newVal) => {
    setName(newVal);
    setDirty(true);
  }, []);

  const [estimatedStationFillTime, setEstimatedStationFillTime] = useState(0);
  const estimatedStationFillTimeChanged = useCallback((newVal) => {
    setEstimatedStationFillTime(newVal);
    setDirty(true);
  }, []);

  const [expectedStationFillTime, setExpectedStationFillTime] = useState(0);
  const expectedStationFillTimeChanged = useCallback((newVal) => {
    setExpectedStationFillTime(newVal);
    setDirty(true);
  }, []);

  const [employeeConfiguration, setEmployeeConfiguration] = useState(null);
  const employeeConfigurationChanged = useCallback((newVal) => {
    setEmployeeConfiguration(newVal);
    setDirty(true);
  }, []);

  const [activityGroups, setActivityGroups] = useState([]);
  const activityGroupsChanged = useCallback((newVal) => {
    setActivityGroups(newVal);
    setDirty(true);
  }, []);

  const [observationInstructions, setObservationInstructions] = useState([]);
  const observationInstructionsChanged = useCallback((newVal) => {
    setObservationInstructions(newVal);
    setDirty(true);
  }, []);

  const [observations, setObservations] = useState([]);
  const observationsChanged = useCallback((newVal) => {
    setObservations(newVal);
    setDirty(true);
  }, []);

  const [expectationData, setExpectationData] = useState(defaultExpectationData);

  const [workingData, setWorkingData] = useState(null);
  useEffect(() => {
    const newData = {
      name,
      estimatedStationFillTime,
      expectedStationFillTime,
      employeeConfiguration,
      activityGroups,
      observationInstructions,
      observations,
    };
    setWorkingData(newData);
  }, [
    name,
    estimatedStationFillTime,
    expectedStationFillTime,
    employeeConfiguration,
    activityGroups,
    observationInstructions,
    observations,
  ]);

  useEffect(() => {
    if (dirty) {
      localStorage.setItem(blueprintId, JSON.stringify(workingData));
    }
  }, [blueprintId, dirty, workingData]);

  useEffect(() => {
    const activityIds = activityGroups
      .reduce((ids, group) => [...ids, ...group.activities.map((a) => a.id)], []);

    setObservationInstructions((prev) => prev
      .filter((obs) => activityIds.includes(obs.activityId)));
    setObservations((prev) => prev.filter((obs) => activityIds.includes(obs.activityId)));
  }, [activityGroups]);

  useEffect(() => {
    if (activityGroups && observations) {
      setExpectationData(calcExpectationData(activityGroups, observations));
    } else {
      setExpectationData(defaultExpectationData);
    }
  }, [activityGroups, observations]);

  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState();
  const formRef = useRef(null);

  const initForm = (evaluationData) => {
    setName(evaluationData.name);

    setEstimatedStationFillTime(evaluationData.estimatedStationFillTime || 0);
    setExpectedStationFillTime(evaluationData.expectedStationFillTime || 0);

    setActivityGroups(evaluationData.activityGroups);
    setObservationInstructions(evaluationData.observationInstructions);
    setObservations(evaluationData.observations);
    setEmployeeConfiguration({ ...defaultEmployeeConfig, ...evaluationData.employeeConfiguration });

    setValidated(false);
  };

  const loadTemplate = useCallback(async (id, wipData) => {
    try {
      const template = await WorkloadAPI.getBlueprint(id);
      setApprovedBy(template.approvedBy);
      setApprovedOn(template.approvedOn);
      setCurrentTemplate(template);
      initForm(wipData || template.data);
    } catch (err) {
      console.error(err);
      setError(err);
    }
  }, [setCurrentTemplate]);

  const revertData = () => {
    setWarning({
      title: 'Revert changes',
      body: 'You will lose any unsaved changes. Are you sure?',
      cancel: 'Keep my changes',
      onCancel: () => setWarning(null),
      confirm: 'Revert',
      onConfirm: () => {
        setSaving(true);
        localStorage.removeItem(blueprintId);
        loadTemplate(blueprintId).then(() => setSaving(false));
        setDirty(false);
        setWarning(null);
      },
    });
  };

  const approveData = useCallback(() => {
    setApproving(true);
    WorkloadAPI.approveBlueprint(blueprintId)
      .then((result) => {
        setApprovedOn(result.approvedOn);
        setApprovedBy(result.approvedBy);
      })
      .catch((reason) => {
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to approve the blueprint',
        });
        console.error(reason);
      })
      .then(() => setApproving(false));
  }, [blueprintId]);

  const showWIPDialog = useCallback((id, wipData) => {
    setWarning({
      title: 'Unsaved changes',
      body: 'It looks like you made some changes that have not been saved',
      cancel: 'Discard them',
      onCancel: () => {
        localStorage.removeItem(id);
        loadTemplate(id);
        setWarning(false);
        setDirty(false);
      },
      confirm: 'Keep them',
      onConfirm: () => {
        loadTemplate(id, wipData);
        setWarning(null);
        setDirty(true);
      },
    });
  }, [loadTemplate]);

  useEffect(() => {
    const wipData = localStorage.getItem(blueprintId);
    if (wipData) {
      showWIPDialog(blueprintId, JSON.parse(wipData));
    } else {
      loadTemplate(blueprintId);
    }
  }, [loadTemplate, showWIPDialog, blueprintId]);

  const getData = useCallback(() => ({
    name,
    estimatedStationFillTime,
    expectedStationFillTime,
    activityGroups,
    observationInstructions,
    observations,
    employeeConfiguration,
    summary: {
      expectedWeeklyWorkload: expectationData.totals.weeklyExpectation,
    },
  }), [
    name,
    estimatedStationFillTime,
    expectedStationFillTime,
    activityGroups,
    observationInstructions,
    observations,
    employeeConfiguration,
    expectationData,
  ]);

  const handleSubmit = useCallback((event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (form.checkValidity()) {
      const data = getData();
      setSaving(true);

      WorkloadAPI.updateBlueprint(blueprintId, data).then((result) => {
        toastService.toast({
          type: 'success',
          message: 'The blueprint was saved successfully',
        });
        setValidated(false);
        setDirty(false);
        localStorage.removeItem(blueprintId);
        loadTemplate(result.id);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to save the blueprint',
        });
      }).then(() => setSaving(false));
    }
  }, [getData, loadTemplate, blueprintId]);

  if (error) {
    return <Error error={error} />;
  }

  function getEditTabs() {
    const weeklyEstimate = activityGroups.reduce((total, group) => total + group.activities
      .reduce((sum, current) => sum + getCalculated(current).weeklyEstimatedSeconds, 0), 0);

    return (
      <div className="h-100 d-flex flex-column">
        <Tab.Container defaultActiveKey="activities" activeKey={activeTab} onSelect={(k) => setActiveTab(k)} unmountOnExit>
          <Nav variant="tabs" className="flex-row" fill>
            <TabHeader eventKey="employeeConfig">Employee config</TabHeader>
            <TabHeader eventKey="activities">Activities</TabHeader>
            <TabHeader eventKey="workloadEstimate">Estimated workload</TabHeader>
            <TabHeader eventKey="paretos">Pareto Analysis</TabHeader>
            <TabHeader eventKey="instructions">Observation plan</TabHeader>
            <TabHeader eventKey="observations">Observations</TabHeader>
            <TabHeader eventKey="expectations">Expectations</TabHeader>
            <TabHeader eventKey="workloadExpectation">Expected workload</TabHeader>
          </Nav>
          <Tab.Content className="h-100">
            <Tab.Pane eventKey="employeeConfig" className="h-100 pt-3">
              <EmployeeConfiguration
                value={employeeConfiguration}
                onChange={employeeConfigurationChanged}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="activities" className="h-100">
              <ActivityGroupsTable value={activityGroups} onChange={activityGroupsChanged} />
            </Tab.Pane>
            <Tab.Pane eventKey="workloadEstimate" className="h-100 pt-3">
              <Workload
                weeklySeconds={weeklyEstimate}
                employeeConfig={employeeConfiguration}
                stationFillTime={estimatedStationFillTime}
                stationFillTimeChanged={estimatedStationFillTimeChanged}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="paretos" className="h-100">
              <ParetoTable
                activityData={activityGroups}
                onCreateObservationPlan={(newInstructions) => {
                  setObservationInstructions(newInstructions);
                  setActiveTab('instructions');
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="instructions" className="h-100">
              <InstructionsTable
                value={observationInstructions}
                onChange={observationInstructionsChanged}
                activityGroups={activityGroups}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="observations" className="h-100">
              <ObservationsTable
                value={observations}
                onChange={observationsChanged}
                activityGroups={activityGroups}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="expectations" className="h-100">
              <ExpectationsTable
                value={activityGroups}
                onChange={activityGroupsChanged}
                expectationData={expectationData}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="workloadExpectation" className="h-100 pt-3">
              <Workload
                weeklySeconds={expectationData.totals.weeklyExpectation}
                employeeConfig={employeeConfiguration}
                stationFillTime={expectedStationFillTime}
                stationFillTimeChanged={expectedStationFillTimeChanged}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }

  function getApproveContent() {
    if (approving) {
      return (
        <>
          <SpinnerIcon className="me-2" />
          <span>Approving...</span>
        </>
      );
    }
    if (approvedBy) {
      const approvedOnDate = new Date(approvedOn).toLocaleString('en-GB');
      const approvedText = `Approved on ${approvedOnDate} by`;
      return (
        <Stack gap={2} direction="horizontal">
          <CheckSquare />
          <span>{approvedText}</span>
          <Username userId={approvedBy} />
        </Stack>
      );
    }
    return (
      <>
        <Square className="me-2" />
        <span>Approve</span>
      </>
    );
  }

  function getForm() {
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
        <fieldset disabled={saving}>
          <Stack direction="horizontal" gap={3}>
            <h2 className="me-auto" style={{ fontStyle: dirty ? 'italic' : null }}>
              {name}
              {dirty ? '*' : ''}
            </h2>
            {saving
              && (
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Saving...</span>
                </Spinner>
              )}
            <Button variant="primary" type="button" onClick={approveData} disabled={dirty || approving}>
              {getApproveContent()}
            </Button>
            <Button variant="primary" type="button" onClick={revertData}>
              <CloudDownload className="me-2" />
              Reload
            </Button>
            <Button variant="secondary" type="submit" disabled={!dirty}>
              <CloudUpload className="me-2" />
              Save current
            </Button>
            <Button type="button" onClick={() => setShowExportModal(true)} disabled={!approvedOn}>
              <BoxArrowUp className="me-2" />
              Copy to client
            </Button>
          </Stack>
          <Row className="mb-3" xl="6">
            <Col>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="string"
                  placeholder="Blueprint name"
                  required
                  value={name}
                  onChange={(e) => nameChanged(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Col className="h-100">
            {getEditTabs()}
          </Col>
        </fieldset>
      </Form>
    );
  }
  return (
    <Row>
      {!!warning && (
        <Warning
          show={!!warning}
          title={warning.title}
          body={warning.body}
          confirm={warning.confirm}
          onConfirm={warning.onConfirm}
          cancel={warning.cancel}
          onCancel={warning.onCancel}
        />
      )}
      <Modal show={showExportModal} onHide={() => setShowExportModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Copy to client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueprintExport blueprintId={blueprintId} />
        </Modal.Body>
      </Modal>
      {currentTemplate
        ? getForm()
        : <Loading />}
    </Row>
  );
}

export default EditBluePrint;
