import { useCallback, useEffect, useState } from 'react';
import {
  Button, Card, Col, Fade, Modal, Row, Stack,
} from 'react-bootstrap';
import { PencilFill, PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import LoadingCards from './loading-cards';
import WorkloadAPI from '../workload-api';
import Error from './error';
import toastService from '../toast-service';
import { useClient } from '../providers/client-provider';
import { useAuth } from '../providers/auth-provider';
import { isAdmin } from '../helper/oauth2';
import doOnKeyPress from '../helper/cards';
import SpinnerIcon from './spinner-icon';

const addNewSize = '7.5em';

function ClientCard({
  client, disabled, onCardClick, onDeleteClick,
}) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const onSelect = () => {
    if (disabled) {
      return;
    }
    onCardClick();
  };

  const onDelete = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    onDeleteClick();
  };

  return (
    <Card disabled={disabled} tabIndex={0} onClick={onSelect} onKeyPress={doOnKeyPress(() => onSelect)} className="workspace-card">
      <Card.Header as={Stack} direction="horizontal">
        <Card.Title>{client.data.name}</Card.Title>
        <Button
          className="ms-auto"
          variant="outline-secondary"
          onClick={(e) => {
            navigate(`client/${client.id}/edit`);
            e.stopPropagation();
          }}
        >
          <PencilFill />

        </Button>
      </Card.Header>
      <Card.Body style={{ overflowY: 'auto' }}>
        <Row>
          <Col>Last modified on</Col>
          <Col>{client.modifiedOn ? new Date(client.modifiedOn).toLocaleString('en-GB') : '-'}</Col>
        </Row>
      </Card.Body>
      {isAdmin(currentUser)
        && (
        <Card.Footer>
          <Button variant="danger" disabled={disabled} onClick={onDelete}>
            <XCircleFill className="me-2" />
            <span>Delete</span>
          </Button>
        </Card.Footer>
        )}
    </Card>
  );
}

function RemoveClientWarning({
  clientId, onHide, onRemoved,
}) {
  const [deleting, setDeleting] = useState(false);

  const deleteSelected = useCallback(() => {
    setDeleting(true);
    WorkloadAPI.deleteClient(clientId).then(
      () => {
        toastService.toast({
          type: 'success',
          message: 'The client was deleted successfully.',
        });
        onRemoved(clientId);
        onHide();
      },
      () => {
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to delete this client.',
        });
      },
    ).finally(() => {
      setDeleting(false);
    });
  }, [clientId, onRemoved, onHide]);

  return (
    <Modal show={!!clientId} onHide={onHide} backdrop={deleting ? 'static' : true}>
      <Modal.Header closeButton={!deleting}>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Removing a client will remove all its departments
        and corresponding evaluations
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={deleting}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteSelected} disabled={deleting}>
          {deleting ? <SpinnerIcon className="me-2" /> : <XCircleFill className="me-2" />}
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ClientCards() {
  const [clients, setClients] = useState(null);
  const [error, setError] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const navigate = useNavigate();
  const { setClient, setDepartments } = useClient();

  const { currentUserInfo } = useAuth();
  const [canAddClient, setCanAddClient] = useState(false);

  useEffect(() => {
    if (!currentUserInfo) {
      return;
    }

    setCanAddClient(currentUserInfo.permissions === 'admin');
    if (currentUserInfo.permissions !== 'admin') {
      if (Object.keys(currentUserInfo.permissions.clients).length === 1) {
        navigate(`client/${Object.keys(currentUserInfo.permissions.clients)[0]}`);
      }
    }
  }, [currentUserInfo, navigate]);

  useEffect(() => {
    setClient(null);
    setDepartments(null);
    const abortController = new AbortController();
    WorkloadAPI.getClients(abortController.signal).then(
      (result) => setClients(result),
      (reason) => {
        if (reason.name !== 'AbortError') {
          setError(reason);
        }
      },
    );
    return () => { abortController.abort(); };
  }, [setClient, setDepartments]);

  const onRemoved = useCallback((removedId) => {
    setClients((prev) => [...prev.filter((c) => c.id !== removedId)]);
  }, [setClients]);

  const onSelected = useCallback((client) => {
    if (client) {
      navigate(`client/${client.id}`);
    }
  }, [navigate]);

  const onAddNewClick = useCallback(() => {
    navigate('client/new');
  }, [navigate]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <RemoveClientWarning
        clientId={deletingId}
        onHide={() => setDeletingId(null)}
        onRemoved={onRemoved}
      />
      <Row>
        <h3>Clients</h3>
      </Row>
      <Row>
        {!clients && <LoadingCards />}
        <Fade in={!!clients}>
          <div className="workspace-card-container">
            {canAddClient && (
            <Card className="workspace-card" onClick={onAddNewClick} onKeyPress={doOnKeyPress(onAddNewClick)} tabIndex={0}>
              <Card.Header>
                <Card.Title>New client</Card.Title>
              </Card.Header>
              <Card.Body style={{ overflowY: 'auto', display: 'flex' }}>
                <PlusCircleFill fill="#003255" fillOpacity={0.75} height={addNewSize} width={addNewSize} style={{ display: 'block', margin: 'auto' }} />
              </Card.Body>
            </Card>
            )}
            {!!clients && clients.map((client) => (
              <ClientCard
                key={client.id}
                client={client}
                disabled={deletingId === client.id}
                onCardClick={() => onSelected(client)}
                onDeleteClick={() => setDeletingId(client.id)}
              />
            ))}
          </div>
        </Fade>
      </Row>
    </>
  );
}

export default ClientCards;
