import WorkloadAPI from '../workload-api';

export default function getAccountName(account) {
  if (!account) {
    return null;
  }

  return account.UserAttributes.find((att) => att.Name === 'email').Value;
}

const userCache = {};
export function getUser(userId, signal) {
  if (!userCache[userId]) {
    userCache[userId] = WorkloadAPI.getAccount(userId, signal)
      // If the promise fails clear it from the cache so we can try again.
      .catch((reason) => {
        userCache[userId] = null;
        throw reason;
      });
  }
  return userCache[userId];
}
