import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PlusCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import WorkloadAPI from '../workload-api';

function SelectClient({ clientChanged }) {
  const [clients, setClients] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (typeof clientChanged === 'function') {
      clientChanged(selected);
    }
  }, [clientChanged, selected]);

  useEffect(() => {
    WorkloadAPI.getClients().then((result) => setClients(result));
  }, []);

  return (
    <Card>
      <Card.Header>
        <Nav variant="pills">
          <Nav.Item className="me-auto">
            <Nav.Link>
              {clients
                ? (
                  <DropdownButton title={selected ? selected.data.name : 'Select a client'}>
                    <Dropdown.Item as="button" onClick={() => setSelected(null)}>None</Dropdown.Item>
                    {clients.map((client) => <Dropdown.Item as="button" key={client.id} onClick={() => setSelected(client)}>{client.data.name}</Dropdown.Item>)}
                  </DropdownButton>
                )
                : (
                  <Button disabled>
                    <Spinner animation="border" role="status" size="sm" className="me-2" />
                    Loading client list...
                  </Button>
                )}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/workspace/client/new">
              <Button>
                <PlusCircle className="me-2" />
                Create new
              </Button>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Header>
      <Collapse in={!!selected}>
        <Card.Body>
          {selected && (
          <>
            <Card.Title>{selected.data.name}</Card.Title>
            <Row>
              <Col>Last modified on</Col>
              <Col>2021/12/12</Col>
            </Row>
          </>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
}

export default SelectClient;
