import {
  getAccessToken, getTokenFromRefresh, clearToken, tryLogin,
} from '../helper/oauth2';
import { openFileForDownload, getFileNameFromHttpResponse } from '../helper/download';

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => {
  const result = {};

  const token = getAccessToken();
  if (token) {
    result.Authorization = `Bearer ${token}`;
  }

  return result;
};

async function allowRefresh(fetchOperation) {
  let response = await fetchOperation();
  if (response.status === 401) {
    try {
      await getTokenFromRefresh();
      response = await fetchOperation();
    } catch (err) {
      clearToken();
      tryLogin();
      return null;
    }
  }
  return response;
}

async function getData(url, signal = null) {
  const response = await allowRefresh(
    () => fetch(url, {
      headers: {
        ...getHeaders(),
      },
      signal,
    }),
  );
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  }
  return response.json();
}

async function getPDF(url, signal = null) {
  const response = await allowRefresh(
    () => fetch(url, {
      headers: {
        ...getHeaders(),
        Accept: 'application/pdf',
      },
      signal,
    }),
  );
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  }
  const pdfData = await response.blob();
  const fileName = getFileNameFromHttpResponse(response);

  return openFileForDownload(pdfData, fileName);
}

async function postData(url, data = {}, signal = null) {
  const response = await allowRefresh(
    () => fetch(url, {
      method: 'POST',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      signal,
    }),
  );
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  }
  return response.json();
}

async function putData(url, data = {}, signal = null) {
  const response = await allowRefresh(
    () => fetch(url, {
      method: 'PUT',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      signal,
    }),
  );
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  }
  return response.json();
}

async function deleteData(url, signal = null) {
  const response = await allowRefresh(
    () => fetch(url, {
      method: 'DELETE',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
      signal,
    }),
  );
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  }
  return response.json();
}

const WorkloadAPI = {

  getStatus: (signal = null) => getData(`${API_URL}/status`, signal),

  getUserInfo: (signal = null) => getData(`${API_URL}/userinfo`, signal),

  getClients: (signal = null) => getData(`${API_URL}/clients`, signal),

  createClient: (data, signal = null) => postData(`${API_URL}/clients`, data, signal),
  getClient: (clientId, signal = null) => getData(`${API_URL}/clients/${clientId}`, signal),
  updateClient: (clientId, clientData, signal = null) => putData(`${API_URL}/clients/${clientId}`, clientData, signal),
  deleteClient: (clientId, signal = null) => deleteData(`${API_URL}/clients/${clientId}`, signal),

  getClientDepartments: (clientId, signal = null) => getData(`${API_URL}/clients/${clientId}/departments`, signal),
  getClientEvaluations: (clientId, signal = null) => getData(`${API_URL}/clients/${clientId}/evaluations`, signal),

  createDepartment: (clientId, data, signal = null) => postData(`${API_URL}/departments`, { clientId, data }, signal),
  getDepartment: (departmentId, signal = null) => getData(`${API_URL}/departments/${departmentId}`, signal),
  updateDepartment: (departmentId, data, signal = null) => putData(`${API_URL}/departments/${departmentId}`, data, signal),
  deleteDepartment: (departmentId, signal = null) => deleteData(`${API_URL}/departments/${departmentId}`, signal),

  getDepartmentEvaluations: (departmentId, signal = null) => getData(`${API_URL}/departments/${departmentId}/evaluations`, signal),

  createEvaluation: (clientId, departmentId, evaluationData, signal = null) => postData(`${API_URL}/evaluations`, { clientId, departmentId, evaluationData }, signal),
  getEvaluation: (evaluationId, signal = null) => getData(`${API_URL}/evaluations/${evaluationId}`, signal),
  updateEvaluation: (evaluationId, evaluationData, signal = null) => putData(`${API_URL}/evaluations/${evaluationId}`, evaluationData, signal),
  deleteEvaluation: (evaluationId, signal = null) => deleteData(`${API_URL}/evaluations/${evaluationId}`, signal),

  copyEvaluation: (evaluationId, evaluationData = {}, signal = null) => postData(`${API_URL}/evaluations/${evaluationId}/copy`, evaluationData, signal),
  getEvaluationPDF: (evaluationId, timeZone, signal = null) => getPDF(`${API_URL}/evaluations/${evaluationId}/pdf?timeZone=${encodeURIComponent(timeZone)}`, signal),

  getAccounts: (role, clientId, signal = null) => {
    let params = `role=${role}`;
    if (clientId) {
      params += `&clientId=${clientId}`;
    }
    return getData(`${API_URL}/admin/accounts?${params}`, signal);
  },
  createAccount: (email, role, attributes, signal = null) => postData(`${API_URL}/admin/accounts`, { email, role, attributes }, signal),
  getAccount: (username, signal = null) => getData(`${API_URL}/admin/accounts/${username}`, signal),
  deleteAccount: (username, signal = null) => deleteData(`${API_URL}/admin/accounts/${username}`, signal),
  /*
  createPermissions: (accountId) => postData(`${API_URL}/admin/accounts/${accountId}/permissions`),
  */
  getPermissions: (accountId, signal = null) => getData(`${API_URL}/admin/accounts/${accountId}/permissions`, signal),
  updatePermissions: (accountId, permissions, signal = null) => putData(`${API_URL}/admin/accounts/${accountId}/permissions`, permissions, signal),

  createBlueprint: (data, signal = null) => postData(`${API_URL}/blueprints`, data, signal),
  getBlueprints: (signal = null) => getData(`${API_URL}/blueprints`, signal),
  importBlueprint: (evaluationId, signal = null) => postData(`${API_URL}/blueprints/import`, { evaluationId }, signal),
  getBlueprint: (blueprintId, signal = null) => getData(`${API_URL}/blueprints/${blueprintId}`, signal),
  updateBlueprint: (blueprintId, data, signal = null) => putData(`${API_URL}/blueprints/${blueprintId}`, data, signal),
  approveBlueprint: (blueprintId, signal = null) => postData(`${API_URL}/blueprints/${blueprintId}/approve`, null, signal),
  copyBlueprint: (blueprintId, signal = null) => postData(`${API_URL}/blueprints/${blueprintId}/copy`, null, signal),
  deleteBlueprint: (blueprintId, signal = null) => deleteData(`${API_URL}/blueprints/${blueprintId}`, signal),
  exportBlueprint: (blueprintId, departmentId, signal = null) => postData(`${API_URL}/blueprints/${blueprintId}/export`, { departmentId }, signal),
};

export default WorkloadAPI;
