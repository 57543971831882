import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import './App.scss';
import Footer from './components/footer';
import Main from './components/main';
import NavMenu from './components/nav-menu';
import { AuthProvider } from './providers/auth-provider';
import Error from './components/error';

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={Error}
      // These are the kind of errors of which we might want to send a log somewhere.
      // onError={(error, info) => console.log(error, info)}
    >
      <DndProvider backend={HTML5Backend}>
        <AuthProvider>
          <div className="App">
            <header className="App-header">
              <NavMenu />
            </header>
            <div className="App-scrollable">
              <main className="App-main">
                <Routes>
                  <Route path="*" element={<Main />} />
                </Routes>
                <Outlet />
              </main>
              <footer className="App-footer">
                <Footer />
              </footer>
            </div>
          </div>
        </AuthProvider>
      </DndProvider>
    </ErrorBoundary>
  );
}

export default App;
