import { Navigate, useLocation } from 'react-router-dom';
import { hasRole } from '../helper/oauth2';
import { useAuth } from '../providers/auth-provider';

function AuthGuard({ children, roles }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser || (!!roles && roles.every(((role) => !hasRole(currentUser, role))))) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default AuthGuard;
