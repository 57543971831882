import { useCallback, useEffect, useState } from 'react';
import {
  Fade, Row, Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDepartment } from '../../providers/department-provider';
import toastService from '../../toast-service';
import WorkloadAPI from '../../workload-api';
import LoadingCards from '../loading-cards';
import Error from '../error';
import { BlueprintCard } from '../blueprints/blueprint-cards';

function AllBlueprints() {
  const navigate = useNavigate();
  const [blueprints, setBlueprints] = useState(null);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { department } = useDepartment();

  useEffect(() => {
    const abortController = new AbortController();
    WorkloadAPI.getBlueprints(abortController.signal).then(
      (result) => {
        const approvedBlueprints = result.filter((bp) => !!bp.approvedBy);
        setBlueprints(approvedBlueprints);
      },
      (reason) => setError(reason),
    );
    return () => { abortController.abort(); };
  }, []);

  const onCardClick = useCallback((blueprint) => {
    setDisabled(true);
    WorkloadAPI.exportBlueprint(blueprint.id, department.id).then((result) => {
      toastService.toast({
        type: 'success',
        message: 'The evaluation was created successfully',
      });
      navigate(`../evaluation/${result.id}`);
    }, (reason) => {
      console.error(reason);
      setError(reason);
      toastService.toast({
        type: 'danger',
        message: 'An error occured trying to create the evaluation!',
      });
    }).finally(() => setDisabled(false));
  }, [navigate, department]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Row>
        <h3>
          Source blueprints
          {disabled && <Spinner animation="border" variant="primary" role="status" className="ms-2" />}
        </h3>
        <span>Please select the blueprint from which to create the evaluation.</span>
      </Row>
      <Row>
        {!blueprints && <LoadingCards />}
        <Fade in={!!blueprints}>
          <div className="workspace-card-container">
            {!!blueprints && blueprints.map((blueprint) => (
              <BlueprintCard
                key={blueprint.id}
                blueprint={blueprint}
                disabled={disabled}
                onCardClick={() => onCardClick(blueprint)}
                onDeleteClick={false}
              />
            ))}
          </div>
        </Fade>
      </Row>
    </>
  );
}

export default AllBlueprints;
