export const getCalculated = (config) => {
  const breakHoursPerFortnight = Math.round(
    100 * config.breakHoursPerDay * config.daysPerFortnight,
  ) / 100;
  // Will be formatted as a percentage so keep 2 extra digits.
  const breakRatio = config.hoursPerFortnight
    ? Math.round((10000 * breakHoursPerFortnight) / config.hoursPerFortnight) / 10000
    : 0;
  return {
    breakHoursPerFortnight,
    breakRatio,
  };
};

export default getCalculated;
