import { useCallback, useState } from 'react';
import { BoxArrowUp } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import { useNavigate } from 'react-router-dom';

import toastService from '../../toast-service';
import WorkloadAPI from '../../workload-api';
import SelectClient from '../select-client';
import SelectDepartment from '../select-department';
import SpinnerIcon from '../spinner-icon';

export default function BlueprintExport({ blueprintId }) {
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [exporting, setExporting] = useState(false);

  const navigate = useNavigate();

  const exportBlueprint = useCallback(() => {
    if (!selectedDepartment || !blueprintId) {
      return;
    }

    setExporting(true);
    WorkloadAPI.exportBlueprint(blueprintId, selectedDepartment.id)
      .then((result) => {
        toastService.toast({
          type: 'success',
          message: 'Blueprint exported successfully.',
        });
        navigate(`/workspace/client/${selectedClient.id}/department/${selectedDepartment.id}/evaluation/${result.id}`);
      })
      .catch((reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'Could not export blueprint.',
        });
        setExporting(false);
      });
  }, [blueprintId, selectedClient, selectedDepartment, navigate]);

  return (
    <>
      <SelectClient clientChanged={setSelectedClient} />
      <Fade in={!!selectedClient}>
        <div>
          {selectedClient && (
            <SelectDepartment
              clientId={selectedClient.id}
              departmentChanged={setSelectedDepartment}
            />
          )}
        </div>
      </Fade>
      <Button className="mt-2 float-end" disabled={!selectedDepartment || exporting} onClick={exportBlueprint}>
        {exporting ? <SpinnerIcon className="me-2" /> : <BoxArrowUp className="me-2" />}
        Export
      </Button>
    </>
  );
}
