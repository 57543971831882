import {
  Route, Routes, Outlet, Navigate,
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ClientCards from './client-cards';
import WorkspaceClient from './workspace-client';
import { ClientProvider } from '../providers/client-provider';
import { DepartmentProvider } from '../providers/department-provider';
import { EvaluationProvider } from '../providers/evaluation-provider';
import WorkspaceStatus from './workspace-status';
import ClientForm from './client/create-client';

function Workspace() {
  return (
    <ClientProvider>
      <DepartmentProvider>
        <EvaluationProvider>
          <InnerWorkspace />
        </EvaluationProvider>
      </DepartmentProvider>
    </ClientProvider>
  );
}

function InnerWorkspace() {
  return (
    <Container className="pt-3">
      <Row>
        <WorkspaceStatus />
      </Row>
      <Routes>
        <Route path="/" element={<ClientCards />} />
        <Route path="client/new" element={<ClientForm />} />
        <Route path="client/:clientId/*" element={<WorkspaceClient />} />
        <Route path="*" element={<Navigate to="./" replace />} />
      </Routes>
      <Outlet />
    </Container>
  );
}

export default Workspace;
