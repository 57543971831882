import { useMemo, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { ChevronDown, ThreeDots } from 'react-bootstrap-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { useTable } from 'react-table';
import { getAverageObservedDuration, getObservedDuration, getShortestObservedDuration } from '../../../helper/observations';
import { NumberCell, DurationCell } from '../../../helper/table';

function RemarksCell({ row, expanded, onClick }) {
  return (
    <Button variant="secondary" onClick={onClick}>
      {expanded[row.id] ? <ChevronDown /> : <ThreeDots />}
    </Button>
  );
}

function TotalCell({ rows }) {
  return (
    <InputGroup className="flex-nowrap">
      <InputGroup.Text>Total:</InputGroup.Text>
      <NumberCell value={rows.length} />
    </InputGroup>
  );
}

function ObservedCell({ value }) {
  return (
    <DurationCell
      value={value}
      minuteProps={{ readOnly: true, disabled: true }}
      secondProps={{ readOnly: true, disabled: true }}
    />
  );
}

function AverageObservedCell({ rows }) {
  const observations = rows.map((row) => row.original);
  const averageSeconds = getAverageObservedDuration(observations);
  const fastest = getShortestObservedDuration(observations);
  return (
    <div className="d-flex flex-column">
      <DurationCell
        value={averageSeconds}
        minuteProps={{ readOnly: true, disabled: true }}
        secondProps={{ readOnly: true, disabled: true }}
        prefixes={<InputGroup.Text className="flex-grow-1">Avg</InputGroup.Text>}
      />
      <DurationCell
        value={fastest}
        minuteProps={{ readOnly: true, disabled: true }}
        secondProps={{ readOnly: true, disabled: true }}
        prefixes={<InputGroup.Text className="flex-grow-1">Min</InputGroup.Text>}
      />
    </div>
  );
}

function ObservationSummary({ observations }) {
  const [expanded, setExpanded] = useState(observations.map(() => false));

  const toggleExpand = (rowId) => {
    setExpanded((e) => [...e.slice(0, rowId), !e[rowId], ...e.slice(rowId + 1)]);
  };

  const columns = useMemo(() => [
    {
      Header: '#',
      accessor: (row, i) => i + 1,
      className: 'text-end',
      Cell: NumberCell,
      Footer: TotalCell,
    },
    {
      Header: 'Observed',
      accessor: (row) => getObservedDuration(row),
      Cell: ObservedCell,
      Footer: AverageObservedCell,
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
      Cell: RemarksCell,
      onClick: (props) => toggleExpand(props.row.id),
    },
  ], []);

  const data = useMemo(() => observations, [observations]);

  const tableInstance = useTable({
    columns,
    data,
    expanded,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Table striped borderless {...getTableProps()} className="mb-0 overflow-auto" style={{ minHeight: '0px', overflowY: 'auto' }}>
      <thead className="sticky-top bg-secondary">
        {// Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps()}>
                    {// Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {!rows.length && (
        <tr role="row">
          <td colSpan={columns.length} style={{ padding: 0 }}>
            <div className="m-3">
              No observations
            </div>
          </td>
        </tr>
        )}
        {// Loop over the table rows
          rows.reduce((trows, row) => {
            // Prepare the row for display
            prepareRow(row);
            trows.push(
              <tr {...row.getRowProps()}>
                {// Loop over the rows cells
                  row.cells.map((cell) => (
                    // Apply the cell props
                    <td {...cell.getCellProps()}>
                      {// Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  ))
                }
              </tr>,
            );
            trows.push(<tr key={`stripe_dummy_${row.index}`} role="row" />);
            trows.push(
              <tr key={`remarks_${row.index}`} role="row">
                <td colSpan={columns.length} style={{ padding: 0 }}>
                  <Collapse in={expanded[row.id]}>
                    <div>
                      <div className="multiline-text" style={{ padding: 8 }}>
                        {observations[row.id].remarks}
                      </div>
                    </div>
                  </Collapse>
                </td>
              </tr>,
            );
            return trows;
          }, [])
}
      </tbody>
      <tfoot className="sticky-bottom bg-secondary align-top">
        {footerGroups.map((group) => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
    </Table>
  );
}

export default ObservationSummary;
