import { useCallback, useEffect, useState } from 'react';
import {
  Dropdown, DropdownButton, Button, Collapse, Stack, Modal,
} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Placeholder from 'react-bootstrap/Placeholder';
import {
  ArrowReturnRight,
  Building,
  CloudUpload,
  DashLg,
  FileRuled,
  PlusCircle,
  PlusLg,
  Star,
  XCircle,
  XLg,
} from 'react-bootstrap-icons';
import { useClients } from '../providers/clients-provider';
import { ClientProvider, useClient } from '../providers/client-provider';
import { DepartmentProvider, useDepartment } from '../providers/department-provider';
import toastService from '../toast-service';
import WorkloadAPI from '../workload-api';
import SpinnerIcon from './spinner-icon';
import Loading from './loading';

const defaultClientPermissions = { permissions: 'read/write', departments: {} };
const defaultDepartmentPermissions = { permissions: 'read/write', evaluations: {} };
const defaultEvaluationPermissions = { permissions: 'read/write' };

function ListItem() {
  return <ArrowReturnRight style={{ margin: '0.75rem' }} />;
}

function LoadingItem() {
  return (
    <Stack direction="horizontal" className="align-items-start">
      <ListItem />
      <Placeholder as={Stack} direction="horizontal" animation="wave" className="flex-grow-1">
        <Placeholder xs={8} size="lg" />
      </Placeholder>
    </Stack>
  );
}

function getEvaluationPermissionsText(evaluations) {
  const numEvaluations = evaluations ? Object.keys(evaluations).length : 0;
  if (numEvaluations === 0) {
    return 'All evaluations';
  }

  if (numEvaluations === 1) {
    return '1 evaluation';
  }

  return `${Object.keys(evaluations).length} evaluations`;
}

function DepartmentPermissions({ value, onChange, onRemove }) {
  const { department, evaluations, setEvaluations } = useDepartment();
  const [showEvaluations, setShowEvaluations] = useState(false);

  useEffect(() => {
    if (!evaluations) {
      const abortController = new AbortController();
      WorkloadAPI.getDepartmentEvaluations(department.id, abortController.signal).then(
        (result) => setEvaluations(result),
        (reason) => {
          if (reason.name === 'AbortError') {
            return;
          }
          console.error(reason);
          toastService.toast({
            type: 'danger',
            message: 'An error occurred while trying to load the department evaluations.',
          });
        },
      );
      return () => { abortController.abort(); };
    }
    return null;
  }, [department, evaluations, setEvaluations]);

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const setAllEvaluations = () => {
    emitChange({ ...value, evaluations: {} });
  };

  const addEvaluation = (evaluationId) => {
    emitChange({
      ...value,
      evaluations: { ...value.evaluations, [evaluationId]: { ...defaultEvaluationPermissions } },
    });
  };

  // const updateEvaluation = (evaluationId, newValue) => {
  //   emitChange({ ...value, evaluations: { ...value.evaluations, [evaluationId]: newValue } });
  // };

  const removeEvaluation = (evaluationId) => {
    const newEvaluations = value.evaluations;
    delete newEvaluations[evaluationId];
    emitChange({ ...value, evaluations: newEvaluations });
  };

  const evaluationIds = Object.keys(value.evaluations);
  const existingEvaluationIds = evaluationIds
    .filter((evaluationId) => evaluations?.some((e) => e.id === evaluationId));
  const availableEvaluations = evaluations
    ?.filter((evaluation) => !evaluationIds.includes(evaluation.id));

  return (
    <div className="flex-grow-1">
      <Stack direction="horizontal" className="permissions-row pe-1">
        <Button variant="outline-link" onClick={() => setShowEvaluations((prev) => !prev)}>
          {showEvaluations ? <DashLg /> : <PlusLg />}
        </Button>
        <Building className="me-2" />
        <span className="flex-grow-1">
          {department.data.name}
          {' '}
          (
          {' '}
          {getEvaluationPermissionsText(value.evaluations)}
          {' '}
          )
        </span>
        <Button variant="outline-danger" size="sm" onClick={onRemove}><XCircle /></Button>
      </Stack>
      <Collapse in={showEvaluations}>
        <div>
          {evaluations
            ? (
              <div className="ps-2">
                {existingEvaluationIds.map((evaluationId) => (
                  <Stack key={evaluationId} direction="horizontal">
                    <ListItem />
                    <Stack direction="horizontal" className="flex-grow-1 permissions-row p-1">
                      <FileRuled className="m-2" />
                      <span className="flex-grow-1">{evaluations.find((e) => e.id === evaluationId).data.department}</span>
                      <Button variant="outline-danger" size="sm" onClick={() => removeEvaluation(evaluationId)}><XCircle /></Button>
                    </Stack>
                  </Stack>
                ))}
                <Stack direction="horizontal">
                  <ListItem />
                  <DropdownButton
                    variant="link"
                    title={(
                      <>
                        <PlusCircle className="me-2" />
                        {existingEvaluationIds.length
                          ? 'Add evaluation permission'
                          : 'Specify evaluation permission'}
                      </>
                    )}
                  >
                    {availableEvaluations && availableEvaluations
                      .map((evaluation) => (
                        <Dropdown.Item
                          key={evaluation.id}
                          onClick={() => addEvaluation(evaluation.id)}
                        >
                          {evaluation.data.department}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                  {existingEvaluationIds.length ? (
                    <>
                      <span>/</span>
                      <Button variant="link" onClick={setAllEvaluations}>
                        <Star className="me-2" />
                        Allow all
                      </Button>
                    </>
                  ) : null}
                </Stack>
              </div>
            )
            : <LoadingItem />}
        </div>
      </Collapse>
    </div>
  );
}

const getDepartmentPermissionsText = (departments) => {
  const numDepts = departments ? Object.keys(departments).length : 0;
  if (numDepts === 0) {
    return 'All departments';
  }

  if (numDepts === 1) {
    return '1 department';
  }

  return `${Object.keys(departments).length} departments`;
};

function ClientPermissions({ value, onChange, onRemove }) {
  const { client, departments, setDepartments } = useClient();
  const [showDepartments, setShowDepartments] = useState(false);

  useEffect(() => {
    if (!departments) {
      const abortController = new AbortController();
      WorkloadAPI.getClientDepartments(client.id, abortController.signal).then(
        (result) => setDepartments(result),
        (reason) => {
          if (reason.name === 'AbortError') {
            return;
          }
          console.error(reason);
          toastService.toast({
            type: 'danger',
            message: 'An error occurred while trying to load the client departments.',
          });
        },
      );
      return () => { abortController.abort(); };
    }
    return null;
  }, [client, departments, setDepartments]);

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const addDepartment = useCallback((departmentId) => {
    emitChange({
      ...value,
      departments: { ...value.departments, [departmentId]: { ...defaultDepartmentPermissions } },
    });
  }, [value, emitChange]);

  const updateDepartment = useCallback((departmentId, newValue) => {
    emitChange({ ...value, departments: { ...value.departments, [departmentId]: newValue } });
  }, [value, emitChange]);

  const removeDepartment = useCallback((departmentId) => {
    const newDepts = value.departments;
    delete newDepts[departmentId];
    emitChange({ ...value, departments: newDepts });
  }, [value, emitChange]);

  const setAllDepartments = useCallback(() => {
    emitChange({ ...value, departments: {} });
  }, [value, emitChange]);

  const departmentIds = Object.keys(value.departments);
  const existingDepartmentIds = departmentIds
    .filter((deptId) => departments?.some((c) => c.id === deptId));
  const availableDepartments = departments?.filter((d) => !departmentIds.includes(d.id));

  return (
    <>
      <Stack direction="horizontal" className="permissions-row pe-1">
        <Button variant="outline-link" onClick={() => setShowDepartments((prev) => !prev)}>
          {showDepartments ? <DashLg /> : <PlusLg />}
        </Button>
        <Building className="me-2" />
        <span className="me-auto">
          {client.data.name}
          {' '}
          (
          {' '}
          {getDepartmentPermissionsText(value.departments)}
          {' '}
          )
        </span>
        <Button variant="outline-danger" size="sm" onClick={onRemove}><XCircle /></Button>
      </Stack>
      <Collapse in={showDepartments}>
        <div>
          {departments
            ? (
              <div className="ps-2">
                {existingDepartmentIds.map((departmentId) => (
                  <Stack key={departmentId} direction="horizontal" className="align-items-start">
                    <ListItem />
                    <DepartmentProvider department={departments.find((d) => d.id === departmentId)}>
                      <DepartmentPermissions
                        value={value.departments[departmentId]}
                        onChange={(newValue) => updateDepartment(departmentId, newValue)}
                        onRemove={() => removeDepartment(departmentId)}
                      />
                    </DepartmentProvider>
                  </Stack>
                ))}

                <Stack direction="horizontal">
                  <ListItem />
                  <DropdownButton
                    variant="link"
                    title={(
                      <>
                        <PlusCircle className="me-2" />
                        {existingDepartmentIds.length
                          ? 'Add department permission'
                          : 'Specify department permission'}
                      </>
                    )}
                  >
                    {availableDepartments && availableDepartments.map((department) => (
                      <Dropdown.Item
                        key={department.id}
                        onClick={() => addDepartment(department.id)}
                      >
                        {department.data.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {existingDepartmentIds.length ? (
                    <>
                      <span>/</span>
                      <Button variant="link" onClick={setAllDepartments}>
                        <Star className="me-2" />
                        Allow all
                      </Button>
                    </>
                  ) : null}
                </Stack>
              </div>
            )
            : <LoadingItem />}
        </div>
      </Collapse>
    </>
  );
}

function AccountPermissionsDetails({ value, onChange }) {
  const { clients } = useClients();

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const addClient = useCallback((clientId) => {
    emitChange({
      ...value,
      clients: { ...value.clients, [clientId]: { ...defaultClientPermissions } },
    });
  }, [value, emitChange]);

  const updateClient = useCallback((clientId, newValue) => {
    emitChange({ ...value, clients: { ...value.clients, [clientId]: newValue } });
  }, [value, emitChange]);

  const removeClient = useCallback((clientId) => {
    const newClients = value.clients;
    delete newClients[clientId];
    emitChange({ ...value, clients: newClients });
  }, [value, emitChange]);

  const clientIds = Object.keys(value.clients);
  const existingClientIds = clientIds.filter((clientId) => clients.some((c) => c.id === clientId));
  const availableClients = clients.filter((c) => !clientIds.includes(c.id));

  return (
    <>
      <div>
        Has access to
        {' '}
        {existingClientIds.length}
        {' '}
        clients
      </div>
      <div>
        {
          existingClientIds.map((clientId) => (
            <ClientProvider key={clientId} client={clients.find((c) => c.id === clientId)}>
              <ClientPermissions
                value={value.clients[clientId]}
                onChange={(newValue) => updateClient(clientId, newValue)}
                onRemove={() => removeClient(clientId)}
              />
            </ClientProvider>
          ))
        }
      </div>
      <div>
        <DropdownButton
          variant="link"
          title={(
            <>
              <PlusCircle className="me-2" />
              {' '}
              Add client permission
            </>
          )}
        >
          {availableClients.map((client) => (
            <Dropdown.Item key={client.id} onClick={() => addClient(client.id)}>
              {client.data.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </>
  );
}

function AccountPermissions({ account, show, onHide }) {
  const [permissions, setPermissions] = useState(null);
  const { clients, setClients } = useClients();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const savePermissions = useCallback(() => {
    setSaving(true);
    WorkloadAPI.updatePermissions(account.Username, permissions).then(
      () => {
        toastService.toast({
          type: 'success',
          message: 'User permissions updated successfully.',
        });
      },
      (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to save the user permissions.',
        });
      },
    ).then(() => setSaving(false));
  }, [account, permissions]);

  const cancelChanges = useCallback(() => {
    if (saving) {
      return;
    }

    setPermissions(null);
    onHide();
  }, [saving, onHide]);

  useEffect(() => {
    if (!clients && show) {
      const abortController = new AbortController();
      WorkloadAPI.getClients(abortController.signal).then(
        (result) => setClients(result),
        (reason) => {
          if (reason.name !== 'AbortError') {
            console.error(reason);
            toastService.toast({
              type: 'danger',
              message: 'An error occurred while trying to load the clients.',
            });
          }
        },
      );
      return () => { abortController.abort(); };
    }
    return null;
  }, [clients, setClients, show]);

  const loadPermissions = useCallback(() => {
    const abortController = new AbortController();
    WorkloadAPI.getPermissions(account.Username, abortController.signal)
      .then((result) => setPermissions(result.data))
      .catch((reason) => {
        if (reason.name === 'AbortError') {
          return;
        }
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to load the user permissions.',
        });
        setError(reason);
      });
    return () => { abortController.abort(); };
  }, [account]);

  useEffect(() => {
    if (!permissions && show) {
      setError(null);
      return loadPermissions();
    }
    return null;
  }, [loadPermissions, show, permissions]);

  const email = account.Attributes.find((a) => a.Name === 'email').Value;
  const loaded = permissions && clients;

  function getBody() {
    if (error) {
      return (
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>
            We were unable to load permissions for this account.
          </p>
          <Button
            variant="link"
            onClick={() => {
              setError(null);
              loadPermissions();
            }}
          >
            Try again

          </Button>
        </Alert>
      );
    }
    if (!loaded) {
      return <Loading />;
    }

    return (
      <AccountPermissionsDetails
        value={permissions}
        onChange={setPermissions}
        disabled={saving}
      />
    );
  }

  return (
    <Modal show={show} onHide={cancelChanges} size="lg" backdrop="static" dialogClassName="h-75">
      <Modal.Header>
        <h4>
          Permissions:
          {' '}
          {email}
        </h4>
      </Modal.Header>
      <Modal.Body>
        {getBody()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelChanges} disabled={saving || !loaded}>
          <XLg className="me-2" />
          <span>Cancel</span>
        </Button>
        <Button onClick={savePermissions} disabled={saving || !loaded}>
          {saving ? <SpinnerIcon className="me-2" /> : <CloudUpload className="me-2" />}
          <span>Save permissions</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AccountPermissions;
