import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  const feedbackUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfYH5TVlikXaLmQ4BqECU6nthaHQ-TsiYn_Jy8E4Mc8Afn04g/viewform?usp=pp_url&entry.128392790=${location.pathname}`;
  return (
    <Navbar bg="light" variant="light">
      <Container>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-center">
          <Navbar.Text>
            <NavLink href={feedbackUrl} tabIndex={-1}>
              Feedback / Bug Report
            </NavLink>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Footer;
