import {
  createContext, useContext, useMemo, useState,
} from 'react';

export const EvaluationContext = createContext({
  evaluation: null,
  setEvaluation: () => {},
});

export function EvaluationProvider({ children }) {
  const [evaluation, setEvaluation] = useState(null);
  const value = useMemo(() => ({
    evaluation, setEvaluation,
  }), [evaluation, setEvaluation]);

  return (
    <EvaluationContext.Provider value={value}>
      {children}
    </EvaluationContext.Provider>
  );
}

export const useEvaluation = () => useContext(EvaluationContext);
