import { getWeeklySeconds } from './frequency';

export function getCalculated(activity) {
  return {
    weeklyEstimatedSeconds:
      getWeeklySeconds(activity.volume, activity.frequency, activity.estimatedSeconds),
  };
}

export default getCalculated;
