export function getObservedDuration(observation) {
  return observation.totalSeconds - observation.lostSeconds;
}

export function getAverageObservedDuration(observations) {
  if (!observations || observations.length === 0) {
    return 0;
  }
  const total = observations.reduce((res, current) => res + getObservedDuration(current), 0);
  return Math.round(total / observations.length);
}

export function getShortestObservedDuration(observations) {
  if (!observations || observations.length === 0) {
    return 0;
  }
  const durations = observations.map((current) => getObservedDuration(current));
  return Math.min(...durations);
}

export function getFactor(activity) {
  if (activity.estimatedSeconds === 0) {
    return 0;
  }
  return activity.expectedSeconds / activity.estimatedSeconds;
}

export function getInstructionFrom(activity) {
  return {
    activityId: activity.id,
    employee: '',
    observer: '',
    observationFrom: new Date().toISOString(),
    observationTo: new Date().toISOString(),
  };
}
