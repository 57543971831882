import { Breadcrumb, Stack } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

function BlueprintCrumb({ location }) {
  const blueprintPath = '/blueprints';

  if (location.pathname === blueprintPath) {
    return <Breadcrumb.Item active> Blueprints</Breadcrumb.Item>;
  }

  return (
    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: blueprintPath }}>
      Blueprints
    </Breadcrumb.Item>
  );
}

function BlueprintStatus() {
  const location = useLocation();

  return (
    <Stack gap={3} direction="horizontal">
      <Breadcrumb>
        <BlueprintCrumb location={location} />
      </Breadcrumb>
    </Stack>
  );
}

export default BlueprintStatus;
