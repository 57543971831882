import { useEffect, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/auth-provider';

function Auth() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { tryLogin, getTokenFromCode } = useAuth();

  const [error, setError] = useState(null);
  const gettingToken = useRef(false);

  const params = new URLSearchParams(search);
  const code = params.get('code');
  const state = params.get('state');

  const working = code && state;

  useEffect(() => {
    if (!gettingToken.current && code && state) {
      gettingToken.current = true;
      getTokenFromCode(code, state).then(
        () => navigate('/'),
        (err) => {
          console.error(err);
          setError(err);
        },
      );
    }
  }, [navigate, code, state, getTokenFromCode]);

  if (working) {
    return (
      <div style={{ margin: 'auto' }}>
        <span>Signing in...</span>
        <Spinner animation="grow" role="status" style={{ display: 'block', margin: 'auto' }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ margin: 'auto' }}>
        <span>An error occurred</span>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column" style={{ margin: 'auto' }}>
      <span>Something went wrong</span>
      <Button variant="link" onClick={() => tryLogin()}>Try again</Button>
    </div>
  );
}

export default Auth;
