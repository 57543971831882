import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useTable } from 'react-table';
import {
  DropdownCell, EditableCell, EditableCellDateTime, IndexCell, SimpleButtonCell,
} from '../../../helper/table';

const defaultValues = {
  activityId: '',
  employee: '',
  observer: '',
  observationFrom: new Date().toISOString(),
  observationTo: new Date().toISOString(),
};

function ActivityCell(props) {
  const { activityGroups } = props;
  return (
    <DropdownCell required {...props}>
      <option>Please select an activity.</option>
      {activityGroups.map((group) => (
        <optgroup label={group.name} key={group.id}>
          {group.activities.map((a) => <option key={a.id} value={a.id}>{a.name}</option>)}
        </optgroup>
      ))}
    </DropdownCell>
  );
}

function ActivityFooter({ addNewInstruction }) {
  return <Button onClick={addNewInstruction} className="w-100">Add instruction</Button>;
}

function ObserverCell(props) {
  const { column, lastCellKeyDown } = props;
  column.onKeyDown = (e) => lastCellKeyDown(e, props);
  return <EditableCell {...props} inputProps={{ type: 'text' }} />;
}

function RemoveCell({ row: { index }, removeInstruction }) {
  return (
    <SimpleButtonCell variant="danger" onClick={() => removeInstruction(index)}>
      <XCircleFill />
    </SimpleButtonCell>
  );
}

function InstructionsTable({ value, onChange, activityGroups }) {
  const wasNewlyAdded = useRef(false);

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const scrollDummy = useRef(null);

  useEffect(() => {
    if (wasNewlyAdded.current) {
      scrollDummy.current.scrollIntoView();
      wasNewlyAdded.current = false;
    }
  }, [value]);

  const updateMyData = useCallback((rowIndex, columnId, newValue) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true);

    emitChange(
      value.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: newValue,
          };
        }
        return row;
      }),
    );
  }, [value, emitChange]);

  const addNewInstruction = useCallback(() => {
    emitChange((a) => [...a, { ...defaultValues }]);
    wasNewlyAdded.current = true;
  }, [emitChange]);

  const removeInstruction = useCallback((i) => {
    emitChange((ins) => [...ins.slice(0, i), ...ins.slice(i + 1)]);
  }, [emitChange]);

  const lastCellKeyDown = useCallback((e, props) => {
    if (e.key === 'Tab' && props.row.index === props.rows.length - 1) {
      props.addNewInstruction();
      e.preventDefault();
    }
  }, []);

  const columns = useMemo(() => [
    {
      id: 'index',
      Header: '',
      Cell: IndexCell,
      rowStyle: { verticalAlign: 'middle' },
    },
    {
      Header: 'Activity',
      accessor: 'activityId',
      Cell: ActivityCell,
      Footer: ActivityFooter,
    },
    {
      Header: 'Employee',
      accessor: 'employee',
      Cell: EditableCell,
      inputProps: { type: 'text' },
    },
    {
      Header: 'From',
      accessor: 'observationFrom',
      Cell: EditableCellDateTime,
    },
    {
      Header: 'To',
      accessor: 'observationTo',
      Cell: EditableCellDateTime,
    },
    {
      Header: 'Observer',
      accessor: 'observer',
      Cell: ObserverCell,
    },
    {
      Header: '',
      id: 'actions',
      Cell: RemoveCell,
    },
  ], []);

  const data = useMemo(() => value, [value]);

  const tableInstance = useTable({
    columns,
    data,
    activityGroups,
    updateMyData,
    addNewInstruction,
    removeInstruction,
    lastCellKeyDown,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="h-100">
      <Table striped borderless hover {...getTableProps()} className="overflow-auto" style={{ minHeight: '0px', overflowY: 'auto' }}>
        <thead className="sticky-top bg-primary">
          {// Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {// Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {// Loop over the rows cells
                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td {...cell.getCellProps({
                        className: cell.column.className,
                        style: { ...cell.column.style, ...cell.column.rowStyle },
                      })}
                      >
                        {// Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
          <tr id="scroll-dummy" ref={scrollDummy} />
        </tbody>
        <tfoot className="sticky-bottom bg-secondary">
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>
    </div>
  );
}

export default InstructionsTable;
