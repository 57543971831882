import { useCallback, useEffect, useState } from 'react';
import {
  Card, Col, Fade, Modal, Row, Button,
} from 'react-bootstrap';
import { PlusCircleFill, Safe2Fill, XCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import doOnKeyPress from '../helper/cards';
import { formatEvaluation } from '../helper/format';
import { isAdmin } from '../helper/oauth2';
import { useAuth } from '../providers/auth-provider';
import { useDepartment } from '../providers/department-provider';
import { useEvaluation } from '../providers/evaluation-provider';
import toastService from '../toast-service';
import WorkloadAPI from '../workload-api';
import Error from './error';
import LoadingCards from './loading-cards';
import SpinnerIcon from './spinner-icon';

const addNewSize = '7.5em';
const formatWeeklyExpectation = (value) => `${Math.floor(value / 60)}m ${Math.round(value % 60)}s`;

function EvaluationCard({
  evaluation, disabled, onCardClick, onDeleteClick,
}) {
  const { currentUser } = useAuth();
  const onSelect = () => {
    if (disabled) {
      return;
    }
    onCardClick();
  };

  const onDelete = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    onDeleteClick();
  };

  return (
    <Card
      disabled={disabled}
      tabIndex={0}
      key={evaluation.id}
      onClick={() => onSelect(evaluation)}
      className="workspace-card"
    >
      <Card.Header>
        <Card.Title>{formatEvaluation(evaluation)}</Card.Title>
      </Card.Header>
      <Card.Body style={{ overflowY: 'auto' }}>
        <Row>
          <Col>Manager</Col>
          <Col>{evaluation.data.manager}</Col>
        </Row>
        <Row>
          <Col>FTEs</Col>
          <Col>{evaluation.data.employeeConfiguration.availableFullTimeEmployees}</Col>
        </Row>
        <Row>
          <Col>Weekly Expectation</Col>
          <Col>{evaluation.data.summary?.expectedWeeklyWorkload ? formatWeeklyExpectation(evaluation.data.summary.expectedWeeklyWorkload) : '-'}</Col>
        </Row>
        <Row>
          <Col>Last modified on</Col>
          <Col>{evaluation.modifiedOn ? new Date(evaluation.modifiedOn).toLocaleString('en-GB') : '-'}</Col>
        </Row>
      </Card.Body>
      {isAdmin(currentUser)
      && (
        <Card.Footer>
          <Button variant="danger" disabled={disabled} onClick={onDelete}>
            <XCircleFill className="me-2" />
            <span>Delete</span>
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
}

function RemoveEvaluationWarning({
  evaluationId, onHide, onRemoved,
}) {
  const [deleting, setDeleting] = useState(false);

  const deleteSelected = () => {
    setDeleting(true);
    WorkloadAPI.deleteEvaluation(evaluationId).then(
      () => {
        toastService.toast({
          type: 'success',
          message: 'The evaluation was deleted successfully',
        });
        onRemoved(evaluationId);
        onHide();
      },
      () => {
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to delete the evaluation',
        });
      },
    ).finally(() => {
      setDeleting(false);
    });
  };

  return (
    <Modal show={!!evaluationId} onHide={onHide} backdrop={deleting ? 'static' : true}>
      <Modal.Header closeButton={!deleting}>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Removing an evaluation will remove all its corresponding data
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={deleting}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteSelected} disabled={deleting}>
          {deleting ? <SpinnerIcon className="me-2" /> : <XCircleFill className="me-2" />}
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function EvaluationCards() {
  const { department, evaluations, setEvaluations } = useDepartment();
  const { setEvaluation } = useEvaluation();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [canAddEvaluation, setCanAddEvaluation] = useState(false);
  const { currentUserInfo } = useAuth();
  const [canCreateFromBlueprint, setCanCreateFromBlueprint] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    if (!department || !currentUserInfo) {
      return;
    }

    if (currentUserInfo.permissions === 'admin') {
      setCanAddEvaluation(true);
      setCanCreateFromBlueprint(true);
    } else {
      const clientId = department.parentIds;
      const clientPermissions = currentUserInfo.permissions.clients[clientId];
      if (Object.keys(clientPermissions.departments).length === 0) {
        setCanAddEvaluation(true);
      } else {
        const departmentPermissions = clientPermissions.departments[department.id];
        if (Object.keys(departmentPermissions.evaluations).length === 1) {
          navigate(`evaluation/${Object.keys(departmentPermissions.evaluations)[0]}`);
        }
        setCanAddEvaluation(Object.keys(departmentPermissions.evaluations).length === 0);
      }
    }
  }, [department, currentUserInfo, navigate]);

  useEffect(() => {
    setEvaluation(null);
    if (department && !evaluations) {
      const abortController = new AbortController();
      WorkloadAPI.getDepartmentEvaluations(department.id).then(
        (result) => setEvaluations(result),
        (reason) => {
          if (reason.name !== 'AbortError') {
            setError(reason);
          }
        },
      );
      return () => { abortController.abort(); };
    }
    return null;
  }, [setEvaluation, department, evaluations, setEvaluations]);

  useEffect(() => {
    if (!department) {
      setEvaluations(null);
    }
  }, [department, setEvaluations]);

  const onSelected = useCallback((evaluation) => {
    if (evaluation) {
      navigate(`evaluation/${evaluation.id}`);
    }
  }, [navigate]);

  const onAddNewClick = useCallback(() => {
    navigate('evaluation/new');
  }, [navigate]);

  const onCreateFromBlueprint = useCallback(() => {
    navigate('evaluation/fromBlueprint');
  }, [navigate]);

  const onRemoved = useCallback((removedId) => {
    setEvaluations((prev) => [...prev.filter((e) => e.id !== removedId)]);
  }, [setEvaluations]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <RemoveEvaluationWarning
        evaluationId={deletingId}
        onHide={() => setDeletingId(null)}
        onRemoved={onRemoved}
      />
      <Row>
        <h3>Evaluations</h3>
      </Row>
      <Row>
        {!evaluations && <LoadingCards />}
        <Fade in={!!evaluations}>
          <div className="workspace-card-container">
            {canAddEvaluation && (
              <Card className="workspace-card" onClick={onAddNewClick} onKeyPress={doOnKeyPress(onAddNewClick)} tabIndex={0}>
                <Card.Header>
                  <Card.Title>{canCreateFromBlueprint ? 'New Evaluation From Scratch' : 'New Evaluation'}</Card.Title>
                </Card.Header>
                <Card.Body style={{ overflowY: 'auto', display: 'flex' }}>
                  <PlusCircleFill fill="#003255" fillOpacity={0.75} height={addNewSize} width={addNewSize} style={{ display: 'block', margin: 'auto' }} />
                </Card.Body>
              </Card>
            )}
            {canCreateFromBlueprint && (
              <Card className="workspace-card" onClick={onCreateFromBlueprint} tabIndex={0}>
                <Card.Header>
                  <Card.Title>New Evaluation From Blueprint</Card.Title>
                </Card.Header>
                <Card.Body style={{ overflowY: 'auto', display: 'flex' }}>
                  <Safe2Fill fill="#003255" fillOpacity={0.75} height={addNewSize} width={addNewSize} style={{ display: 'block', margin: 'auto' }} />
                </Card.Body>
              </Card>
            )}
            {evaluations && evaluations.map((evaluation) => (
              <EvaluationCard
                key={evaluation.id}
                evaluation={evaluation}
                disabled={deletingId === evaluation.id}
                onCardClick={() => onSelected(evaluation)}
                onDeleteClick={() => setDeletingId(evaluation.id)}
              />
            ))}
          </div>
        </Fade>
      </Row>
    </>
  );
}

export default EvaluationCards;
