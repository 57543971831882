import { useEffect, useState } from 'react';
import {
  Route, Routes, Outlet, useParams, Navigate,
} from 'react-router-dom';

import WorkloadAPI from '../workload-api';
import EditEvaluation from './evaluation/edit-evaluation';
import EvaluationCards from './evaluation-cards';
import Error from './error';
import { useDepartment } from '../providers/department-provider';
import EvaluationForm from './evaluation/create-evaluation';
import EditDepartmentForm from './department/edit-department';
import AllBlueprints from './evaluation/choose-blueprint';

function WorkspaceDepartment() {
  const { departmentId } = useParams();
  const { setDepartment } = useDepartment();
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    WorkloadAPI.getDepartment(departmentId, abortController.signal).then(
      (result) => setDepartment(result),
      (reason) => {
        if (reason.name !== 'AbortError') {
          setError(reason);
        }
      },
    );
    return () => { abortController.abort(); };
  }, [departmentId, setDepartment]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<EvaluationCards />} />
        <Route path="edit" element={<EditDepartmentForm />} />
        <Route path="evaluation/new" element={<EvaluationForm />} />
        <Route path="evaluation/:evaluationId" element={<EditEvaluation />} />
        <Route path="evaluation/fromBlueprint" element={<AllBlueprints />} />
        <Route path="*" element={<Navigate to="./" replace />} />
      </Routes>
      <Outlet />
    </>
  );
}

export default WorkspaceDepartment;
