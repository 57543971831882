import { useEffect, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

import getAccountName, { getUser } from '../helper/accounts';
import toastService from '../toast-service';

export default function Username({ userId }) {
  const [name, setName] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    getUser(userId, abortController.signal)
      .then((result) => setName(getAccountName(result)))
      .catch((reason) => {
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to get user data.',
        });
        console.error(reason);
      });
    return () => { abortController.abort(); };
  }, [userId]);

  if (!name) {
    return (
      <Placeholder animation="wave">
        <Placeholder aria-hidden="true">placeholder name</Placeholder>
      </Placeholder>
    );
  }

  return <span>{name}</span>;
}
