import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ArrowRightCircle, DashSquareFill, PlusSquareFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { useTable } from 'react-table';
import { getCalculated } from '../../../helper/activities';
import { formatFrequency, roundPercentageDetailed } from '../../../helper/format';
import { getInstructionFrom } from '../../../helper/observations';
import { IndexCell, ReadonlyDurationCell, TextCell } from '../../../helper/table';

const paretoLimit = 0.8;

const IncludeParetoStyle = {
  fontWeight: 'bold',
};

function ActivityCell(props) {
  return <TextCell {...props} style={{ minWidth: '30em !important' }} />;
}

function WeeklyFooter({ data }) {
  const total = data.reduce((sum, row) => row.weeklySeconds + sum, 0);

  return <ReadonlyDurationCell value={total} />;
}

function WorkloadCell(props) {
  const { value, style } = props;
  const pct = roundPercentageDetailed(100 * value);
  return (
    <InputGroup className="flex-nowrap">
      <TextCell className="text-end" {...props} style={{ ...style, maxWidth: '100px' }} value={pct} disabled />
      <InputGroup.Text>%</InputGroup.Text>
    </InputGroup>
  );
}

function ParetoCell(props) {
  const cellProps = { ...props };
  const { row: { original } } = props;
  const { includePareto } = original;
  if (includePareto) {
    cellProps.style = IncludeParetoStyle;
  }
  const pct = roundPercentageDetailed(100 * cellProps.value);
  return (
    <InputGroup className="flex-nowrap">
      <InputGroup.Text>
        {includePareto
          ? <PlusSquareFill className="text-success" size="1.5em" />
          : <DashSquareFill className="text-warning" size="1.5em" />}
      </InputGroup.Text>
      <TextCell className="text-end" {...cellProps} value={pct} disabled />
      <InputGroup.Text>%</InputGroup.Text>
    </InputGroup>
  );
}

function ParetoFooter({ data, createObservationPlan }) {
  return (
    <Button onClick={() => createObservationPlan(data.filter((row) => row.includePareto))} className="w-100">
      <ArrowRightCircle className="me-2" />
      Create plan
    </Button>
  );
}

function VolumeCell({ value }) {
  const unit = `${value.unit} / ${formatFrequency(value.freq)}`;
  return (
    <InputGroup className="flex-nowrap">
      <InputGroup.Text style={{ flex: '0 0 3em' }}>{value.value}</InputGroup.Text>
      <TextCell style={{ flex: '1 1 0', width: '10em' }} value={unit} />
    </InputGroup>
  );
}

function ParetoTable(props) {
  const { activityData, onCreateObservationPlan } = props;

  const [paretos, setParetos] = useState([]);

  useEffect(() => {
    let newParetos = activityData
      .reduce((result, ag) => [...result, ...ag.activities.map((a) => {
        const calculated = getCalculated(a);
        return {
          activity: a,
          weeklySeconds: calculated.weeklyEstimatedSeconds,
        };
      })], [])
      .sort((a, b) => b.weeklySeconds - a.weeklySeconds);

    const totalWeeklySeconds = newParetos
      .reduce((total, current) => total + current.weeklySeconds, 0);
    let runningTotal = 0;
    newParetos = newParetos.map((p, i) => {
      const parRes = p;
      parRes.workload = p.weeklySeconds / totalWeeklySeconds;
      runningTotal += p.workload;
      parRes.pareto = runningTotal;
      parRes.includePareto = i === 0 || runningTotal < paretoLimit;
      return parRes;
    });

    setParetos(newParetos);
  }, [activityData]);

  const createObservationPlan = useCallback((relevantRows) => {
    const observationInstructions = relevantRows.map((row) => getInstructionFrom(row.activity));
    if (typeof onCreateObservationPlan === 'function') {
      onCreateObservationPlan(observationInstructions);
    }
  }, [onCreateObservationPlan]);

  const columns = useMemo(
    () => [
      {
        id: 'index',
        Header: '',
        Cell: IndexCell,
        rowStyle: { verticalAlign: 'middle' },
      },
      {
        Header: 'Activity',
        accessor: 'activity.name',
        Cell: ActivityCell,
        className: 'sticky-left',
        style: { width: '99%' },
      },
      {
        Header: 'Volume',
        accessor: (row) => ({
          value: row.activity.volume,
          unit: row.activity.unit,
          freq: row.activity.frequency,
        }),
        Cell: VolumeCell,
        style: { minWidth: '10em' },
      },
      {
        Header: 'Estimate',
        accessor: 'activity.estimatedSeconds',
        Cell: ReadonlyDurationCell,
        style: { minWidth: '10em' },
      },
      {
        Header: 'Weekly',
        accessor: 'weeklySeconds',
        Cell: ReadonlyDurationCell,
        style: { minWidth: '10em' },
        Footer: WeeklyFooter,
      },
      {
        Header: 'Workload',
        accessor: 'workload',
        Cell: WorkloadCell,
        style: { minWidth: '10em' },
      },
      {
        Header: 'Pareto',
        accessor: 'pareto',
        Cell: ParetoCell,
        style: { minWidth: '11em', maxWidth: '200px' },
        Footer: ParetoFooter,
      },
    ],
    [],
  );

  const data = useMemo(() => paretos, [paretos]);

  const tableInstance = useTable({
    columns,
    data,
    // updateMyData,
    createObservationPlan,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Table striped borderless hover {...getTableProps()} className="overflow-auto" style={{ minHeight: '0px', overflowY: 'auto' }}>
      <thead className="sticky-top bg-primary">
        {// Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps()}>
                    {// Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {// Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {// Loop over the rows cells
                  row.cells.map((cell) => (
                    // Apply the cell props
                    <td {...cell.getCellProps({
                      className: cell.column.className,
                      style: { ...cell.column.style, ...cell.column.rowStyle },
                    })}
                    >
                      {// Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  ))
                }
              </tr>
            );
          })
        }
      </tbody>
      <tfoot className="sticky-bottom bg-secondary">
        {footerGroups.map((group) => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <td {...column.getFooterProps()} style={column.style}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
    </Table>
  );
}

export default ParetoTable;
