import { useCallback, useMemo, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  CalculatorFill, CaretDown, CaretRight, EyeFill, PencilFill, ThreeDots,
} from 'react-bootstrap-icons';
import { useTable } from 'react-table';
import { FormGroup } from 'react-bootstrap';
import { formatFrequency, roundPercentage } from '../../../helper/format';
import ObservationSummary from '../observation/observation-summary';
import {
  DurationCell, EditableCellDuration, IndexCell, ReadonlyDurationCell, TextCell,
} from '../../../helper/table';
import { getWeeklySeconds } from '../../../helper/frequency';

function GroupHandle({ isExpanded, onClick }) {
  return (
    <div
      style={{ marginTop: '6px', cursor: 'pointer' }}
      className="d-inline-block"
      onClick={onClick}
      onKeyPress={onClick}
      role="switch"
      aria-checked={isExpanded}
      tabIndex={0}
    >
      {isExpanded ? <CaretDown /> : <CaretRight />}
    </div>
  );
}

function GroupRow({ name, columns, children }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <tr>
        <th colSpan={columns} className="align-bottom">
          <div className="d-inline-block sticky-left">
            <GroupHandle isExpanded={!collapsed} onClick={() => setCollapsed((c) => !c)} />
            <span className="d-inline-block px-2">{name}</span>
          </div>
        </th>
      </tr>
      {children({ collapsed })}
    </>
  );
}

function ObservedAverageCell({ row, showObservations, expectationData }) {
  const value = expectationData.expectations.find((e) => e.activity.id === row.original.id)
    .observedAverage;
  return (
    <InputGroup className="flex-nowrap">
      <Button variant="secondary" onClick={() => showObservations(row)}>
        <EyeFill />
      </Button>
      <DurationCell value={value} readOnly disabled />
    </InputGroup>
  );
}

function FactorCell({ row: { original: { id } }, column, expectationData }) {
  const value = expectationData.expectations.find((e) => e.activity.id === id).factor;
  if (value) {
    return <TextCell className="text-end" value={`${roundPercentage(100 * value)} %`} />;
  }
  return column.Footer({ style: { background: 'white' }, expectationData });
}

function FactorFooter({ expectationData }) {
  return (
    <InputGroup className="flex-nowrap">
      <InputGroup.Text>Avg</InputGroup.Text>
      <TextCell className="text-end" disabled value={`${roundPercentage(100 * expectationData.totals.averageFactor)} %`} style={{ minWidth: '4em' }} />
    </InputGroup>
  );
}

function VolumeCell({ value }) {
  const unit = `${value.unit} / ${formatFrequency(value.freq)}`;
  return (
    <InputGroup className="flex-nowrap">
      <InputGroup.Text style={{ flex: '0 0 3em' }}>{value.value}</InputGroup.Text>
      <TextCell style={{ flex: '1 1 0', width: '10em' }} value={unit} />
    </InputGroup>
  );
}

function ExpectedCell(props) {
  const { column, row: { original: { id } }, expectationData } = props;
  const relevantData = expectationData.expectations.find((e) => e.activity.id === id);
  const isManual = relevantData.observations.length > 0;
  return (
    <InputGroup className="flex-nowrap">
      <InputGroup.Text>
        {isManual
          ? <PencilFill />
          : <CalculatorFill />}
      </InputGroup.Text>
      {isManual
        ? <EditableCellDuration {...props} />
        : <DurationCell readOnly disabled value={relevantData.expectedSeconds} />}
      <Button variant="secondary" onClick={() => column.onClickRemarks(props)}>
        <ThreeDots />
      </Button>
    </InputGroup>
  );
}

function ObservedAverageFooter({ expectationData }) {
  return (
    <div className="d-flex flex-column">
      <InputGroup className="flex-nowrap">
        <InputGroup.Text style={{ width: '4em' }}>Time</InputGroup.Text>
        <TextCell className="text-end" disabled value={`${roundPercentage(100 * expectationData.totals.observedTimeRatio)} %`} style={{ minWidth: '4em' }} />
      </InputGroup>
      <InputGroup className="flex-nowrap">
        <InputGroup.Text style={{ width: '4em' }}>Items</InputGroup.Text>
        <TextCell className="text-end" disabled value={`${roundPercentage(100 * expectationData.totals.observedUnitsRatio)} %`} style={{ minWidth: '4em' }} />
      </InputGroup>
    </div>
  );
}

function WeeklyExpectationCell({ row: { original: { volume, frequency, id } }, expectationData }) {
  const { expectedSeconds } = expectationData.expectations.find((e) => e.activity.id === id);
  const value = getWeeklySeconds(volume, frequency, expectedSeconds);
  return <ReadonlyDurationCell value={value} />;
}

function WeeklyExpectationFooter({ expectationData }) {
  return <ReadonlyDurationCell value={expectationData.totals.weeklyExpectation} />;
}

function ExpectationsTable(props) {
  const { value, onChange, expectationData } = props;

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const [showSummary, setShowSummary] = useState(null);
  const [expectedSeconds, setExpectedSeconds] = useState(null);

  const [modalProps, setModalProps] = useState(null);
  const [modalValue, setModalValue] = useState(null);

  const activities = useMemo(() => value
    .reduce((result, currentGroup) => result
      .concat(currentGroup.activities
        .map((a, activityIndex) => ({
          ...a,
          groupId: currentGroup.id,
          relativeIndex: activityIndex,
        }))), []), [value]);
  const data = useMemo(() => activities, [activities]);

  const updateRow = useCallback((row, prop, newValue) => {
    emitChange(
      value.map((group) => {
        if (group.id === row.groupId) {
          return {
            ...group,
            activities: group.activities.map((activity) => {
              if (activity.id === row.id) {
                return {
                  ...activity,
                  [prop]: newValue,
                };
              }
              return activity;
            }),
          };
        }
        return group;
      }),
    );
  }, [value, emitChange]);

  const updateMyData = useCallback((rowIndex, columnId, newValue) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true);

    const row = data[rowIndex];

    return updateRow(row, columnId, newValue);
  }, [data, updateRow]);

  const editModally = (name, title, propName, rowIndex, initialValue) => {
    setModalProps({
      title,
      name,
      propName,
      rowIndex,
    });
    setModalValue(initialValue);
  };

  const onHideModal = () => {
    updateMyData(modalProps.rowIndex, modalProps.propName, modalValue);
    setModalProps(null);
    setModalValue(null);
  };

  const columns = useMemo(() => [
    {
      id: 'index',
      Header: '',
      Cell: IndexCell,
      rowStyle: { verticalAlign: 'middle' },
    },
    {
      Header: 'Activity',
      accessor: 'name',
      Cell: TextCell,
      className: 'sticky-left',
      style: { width: '99%' },
    },
    {
      Header: 'Estimated',
      accessor: 'estimatedSeconds',
      Cell: ReadonlyDurationCell,
      style: { minWidth: '10em' },
    },
    {
      Header: 'Observed',
      id: 'observedAverage',
      Cell: ObservedAverageCell,
      style: { minWidth: '12em' },
      Footer: ObservedAverageFooter,
    },
    {
      Header: 'Factor',
      id: 'factor',
      Cell: FactorCell,
      style: { minWidth: '8em' },
      Footer: FactorFooter,
    },
    {
      Header: 'Expected',
      accessor: 'expectedSeconds',
      Cell: ExpectedCell,
      style: { minWidth: '15em' },
      onClickRemarks: ({ row }) => editModally(
        row.original.name,
        'Remarks',
        'expectedRemarks',
        row.index,
        row.original.expectedRemarks,
      ),
    },
    {
      Header: 'Volume',
      id: 'volume',
      accessor: (row) => ({ value: row.volume, unit: row.unit, freq: row.frequency }),
      Cell: VolumeCell,
      style: { minWidth: '13em' },
    },
    {
      Header: 'Expected weekly',
      id: 'weeklyExpectation',
      Cell: WeeklyExpectationCell,
      style: { minWidth: '10em' },
      Footer: WeeklyExpectationFooter,
    },
  ], []);

  const showObservations = (row) => {
    const rowData = expectationData.expectations.find((e) => e.activity.id === row.original.id);
    setExpectedSeconds(row.original.expectedSeconds);
    setShowSummary({
      activity: row.original,
      observations: rowData.observations,
    });
  };

  const tableInstance = useTable({
    columns,
    data,
    expectationData,
    updateMyData,
    showObservations,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const getGroupedRows = () => (
    value.map((group) => {
      const relevantRows = rows.filter((r) => r.original.groupId === group.id);
      return (
        <GroupRow key={group.id} name={group.name} columns={columns.length}>
          {(parentProps) => relevantRows.map(
            (row) => {
              const visibility = parentProps.collapsed ? 'collapse' : 'visible';
              return prepareRow(row) || (
              <tr key={row.id} {...row.getRowProps()} style={{ visibility }}>
                {// Loop over the rows cells
                  row.cells.map((cell) => (
                    // Apply the cell props
                    <td {...cell.getCellProps({
                      className: `${cell.column.className}  readonly-light`,
                      style: { ...cell.column.style, ...cell.column.rowStyle },
                    })}
                    >
                      {// Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  ))
                }
              </tr>
              );
            },
          )}
        </GroupRow>
      );
    })
  );

  return (
    <div className="h-100">
      <Modal show={!!modalProps} onHide={onHideModal} centered size="lg">
        {!!modalProps && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalProps.name}
              {' '}
              {modalProps.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control as="textarea" rows={10} value={modalValue} onChange={(e) => setModalValue(e.target.value)} />
          </Modal.Body>
        </>
        )}
      </Modal>
      {/* MODAL SHOW OBSERVATIONS */}
      <Modal
        show={!!showSummary}
        onHide={() => {
          if (!showSummary) {
            return;
          }
          updateRow(showSummary.activity, 'expectedSeconds', expectedSeconds);
          setShowSummary(null);
        }}
        centered
        size="lg"
      >
        {showSummary && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {showSummary.activity.name}
              {' '}
              observations
            </Modal.Title>
          </Modal.Header>
          {showSummary.observations.length > 0 && (
          <Modal.Body>
            <Container>
              <Row xs="6">
                <FormGroup>
                  <Form.Label>Expected</Form.Label>
                  <DurationCell value={expectedSeconds} onChange={(e) => setExpectedSeconds(e)} />
                </FormGroup>
              </Row>
            </Container>
          </Modal.Body>
          )}
          {/* <Modal.Body> */}
          <ObservationSummary {...showSummary} />
          {/* </Modal.Body> */}
        </>
        )}
      </Modal>
      <Table striped borderless hover {...getTableProps()}>
        <thead className="sticky-top bg-primary">
          {// Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps({
                      className: [column.className, 'bg-primary'].join(' '),
                      style: { ...column.style },
                    })}
                    >
                      {// Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {getGroupedRows()}
        </tbody>
        <tfoot className="sticky-bottom bg-secondary align-top">
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps({
                  className: [column.className, 'bg-secondary'].join(' '),
                  style: column.style,
                })}
                >
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>
    </div>
  );
}

export default ExpectationsTable;
