import Spinner from 'react-bootstrap/Spinner';

function SpinnerIcon({ className }) {
  return (
    <Spinner className={className} animation="border" role="status" variant="info" size="sm">
      <span className="visually-hidden">Saving...</span>
    </Spinner>
  );
}

export default SpinnerIcon;
