import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Accounts from './accounts';

function Admin() {
  return (
    <Container className="pt-3">
      <Row>
        <h3>Accounts and access</h3>
      </Row>
      <Row>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5>Administrators</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Accounts authRole="admin" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5>Clients</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Accounts authRole="client" />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </Container>
  );
}

export default Admin;
