import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { ArrowClockwise } from 'react-bootstrap-icons';

// We might use the actual error in the future.
// eslint-disable-next-line no-unused-vars
function Error({ error }) {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <div>
        Something went wrong. Try
        <Button variant="link" onClick={() => reloadPage()}>
          <ArrowClockwise className="me-1" />
          reloading the page.
        </Button>
      </div>
    </Container>
  );
}

export default Error;
