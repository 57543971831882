import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { getCalculated } from '../../../helper/employeeConfiguration';

function PercentageControl(props) {
  const { value, onChange } = props;
  const [percentage, setPercentage] = useState((value * 100) || 0);

  useEffect(() => {
    // Fix floating point accuracy nonsense.
    const pct = Math.round(value * 10000) / 100;
    setPercentage(pct || 0);
  }, [value]);

  const percentageChanged = (e) => {
    const newVal = e.target.value;
    setPercentage(newVal);
    if (typeof onChange === 'function') {
      onChange(newVal / 100);
    }
  };

  return (
    <InputGroup>
      <Form.Control
        type="number"
        className="text-end"
        {...props}
        value={percentage}
        onChange={percentageChanged}
      />
      <InputGroup.Text>%</InputGroup.Text>
    </InputGroup>
  );
}

function EmployeeConfiguration({ value, onChange }) {
  const [calculatedValues, setCalculatedValues] = useState({
    breakHoursPerFortnight: 0,
    breakRatio: 0,
  });

  useEffect(() => {
    if (value) {
      setCalculatedValues(getCalculated(value));
    }
  }, [value]);

  const anyValueChanged = (name, newVal) => {
    const result = { ...value, [name]: newVal };
    if (typeof onChange === 'function') {
      onChange(result);
    }
  };

  const controlValueChanged = (event) => {
    anyValueChanged(event.target.name, event.target.value);
  };

  return value && (
    <Container>
      <Row className="mb-3" xl="6" xs="3">
        <Form.Group as={Col}>
          <Form.Label>Available full-time employees</Form.Label>
          <Form.Control
            type="number"
            className="text-end"
            min={0}
            step="any"
            name="availableFullTimeEmployees"
            value={value.availableFullTimeEmployees}
            onChange={controlValueChanged}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3" xl="6">
        <Form.Group as={Col}>
          <Form.Label>Hours per 2 weeks</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              className="text-end"
              min={0}
              name="hoursPerFortnight"
              value={value.hoursPerFortnight}
              onChange={controlValueChanged}
            />
            <InputGroup.Text>hours / 2 weeks</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Workdays per 2 weeks</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              className="text-end"
              min={0}
              name="daysPerFortnight"
              value={value.daysPerFortnight}
              onChange={controlValueChanged}
            />
            <InputGroup.Text>days / 2 weeks</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Employee absence</Form.Label>
          <PercentageControl min={0} max={100} value={value.employeeAbsenceFactor} onChange={(newVal) => anyValueChanged('employeeAbsenceFactor', newVal)} />
        </Form.Group>
      </Row>
      <Row className="mb-3" xl="6">
        <Form.Group as={Col}>
          <Form.Label>Breaks</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              className="text-end"
              min={0}
              step={0.01}
              name="breakHoursPerDay"
              value={value.breakHoursPerDay}
              onChange={controlValueChanged}
            />
            <InputGroup.Text>hours / day</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Breaks per 2 weeks</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              className="text-end"
              value={calculatedValues.breakHoursPerFortnight}
              disabled
            />
            <InputGroup.Text>hours / 2 weeks</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Break ratio</Form.Label>
          <PercentageControl value={calculatedValues.breakRatio} disabled />
        </Form.Group>
      </Row>
    </Container>
  );
}

export default EmployeeConfiguration;
