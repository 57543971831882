import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import WorkloadAPI from '../workload-api';
import toastService from '../toast-service';
import {
  config, clearToken, getCurrentUser, getIDToken, getUrlSearchParams, tryLogin,
} from '../helper/oauth2';

export const AuthContext = createContext({
  currentUser: null,
  currentUserInfo: null,
  tryLogin,
  getTokenFromCode: null,
  logout: null,
});

export function AuthProvider({ children }) {
  const currentToken = getIDToken();
  const [currentUser, setCurrentUser] = useState(currentToken
    ? getCurrentUser(currentToken)
    : null);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);

  useEffect(() => {
    if (!currentUser) {
      setCurrentUserInfo(null);
    } else {
      WorkloadAPI.getUserInfo().then(
        (result) => setCurrentUserInfo(result),
        () => {
          toastService.toast({
            type: 'danger',
            message: 'An error occurred while trying to load your user data.',
          });
        },
      );
    }
  }, [currentUser]);

  const value = useMemo(() => {
    const logout = async () => {
      clearToken();
      setCurrentUser(null);
      const params = {
        client_id: config.clientId,
        logout_uri: config.logoutUrl,
      };
      const destination = `${config.endpoints.logout}?${getUrlSearchParams(params).toString()}`;
      window.location = destination;
    };

    const getTokenFromCode = async (authCode, state) => {
      const savedState = window.localStorage.getItem('state');
      if (!savedState || state !== savedState) {
        throw new Error('Possible token interception detected.');
      }

      const params = {
        grant_type: 'authorization_code',
        client_id: config.clientId,
        redirect_uri: config.redirectUrl,
        code: authCode,
      // code_verifier
      };

      const tokenResponse = await fetch(
        config.endpoints.token,
        {
          method: 'POST',
          headers: [
            ['Content-Type', 'application/x-www-form-urlencoded'],
          ],
          body: getUrlSearchParams(params),
        },
      );

      if (tokenResponse.status !== 200) {
        const msg = await tokenResponse.text();
        throw new Error(msg);
      }

      const tokenResult = await tokenResponse.json();
      // tokenResult.id_token
      // tokenResult.refresh_token
      window.localStorage.setItem('access_token', tokenResult.access_token);
      window.localStorage.setItem('id_token', tokenResult.id_token);
      window.localStorage.setItem('refresh_token', tokenResult.refresh_token);
      window.localStorage.removeItem('state');

      setCurrentUser(getCurrentUser(tokenResult.id_token));
    };

    return {
      currentUser, currentUserInfo, tryLogin, getTokenFromCode, logout,
    };
  }, [currentUser, currentUserInfo]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
