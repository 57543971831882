import { useEffect, useState, useCallback } from 'react';
import { PencilFill, PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Fade from 'react-bootstrap/Fade';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';

import { useClient } from '../providers/client-provider';
import { useDepartment } from '../providers/department-provider';
import { useAuth } from '../providers/auth-provider';
import { isAdmin } from '../helper/oauth2';
import WorkloadAPI from '../workload-api';
import doOnKeyPress from '../helper/cards';
import LoadingCards from './loading-cards';
import SpinnerIcon from './spinner-icon';
import Error from './error';
import toastService from '../toast-service';

const addNewSize = '7.5em';

function RemoveDepartmentWarning({
  departmentId, onHide, onRemoved,
}) {
  const [deleting, setDeleting] = useState(false);

  const deleteSelected = useCallback(() => {
    setDeleting(true);
    WorkloadAPI.deleteDepartment(departmentId).then(
      () => {
        toastService.toast({
          type: 'success',
          message: 'The department was deleted successfully.',
        });
        onRemoved(departmentId);
        onHide();
      },
      () => {
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to delete this department.',
        });
      },
    ).finally(() => {
      setDeleting(false);
    });
  }, [departmentId, onRemoved, onHide]);

  return (
    <Modal show={!!departmentId} onHide={onHide} backdrop={deleting ? 'static' : true}>
      <Modal.Header closeButton={!deleting}>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Removing a department will remove all its
        corresponding evaluations.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={deleting}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteSelected} disabled={deleting}>
          {deleting ? <SpinnerIcon className="me-2" /> : <XCircleFill className="me-2" />}
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function DepartmentCard({
  department, disabled, onCardClick, onDeleteClick,
}) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const onSelect = useCallback(() => {
    if (disabled) {
      return;
    }
    onCardClick();
  }, [disabled, onCardClick]);

  const onDelete = useCallback((e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    onDeleteClick();
  }, [disabled, onDeleteClick]);

  return (
    <Card
      className="workspace-card"
      disabled={disabled}
      tabIndex={0}
      onClick={onSelect}
      onKeyPress={doOnKeyPress(() => onSelect)}
    >
      <Card.Header as={Stack} direction="horizontal">
        <Card.Title>{department.data.name}</Card.Title>
        <Button
          className="ms-auto"
          variant="outline-secondary"
          onClick={(e) => {
            navigate(`department/${department.id}/edit`);
            e.stopPropagation();
          }}
        >
          <PencilFill />
        </Button>
      </Card.Header>
      <Card.Body style={{ overflowY: 'auto' }}>
        <Row>
          <Col>Last modified on</Col>
          <Col>{department.modifiedOn ? new Date(department.modifiedOn).toLocaleString('en-GB') : '-'}</Col>
        </Row>
      </Card.Body>
      {isAdmin(currentUser)
        && (
        <Card.Footer>
          <Button variant="danger" disabled={disabled} onClick={onDelete}>
            <XCircleFill className="me-2" />
            <span>Delete</span>
          </Button>
        </Card.Footer>
        )}
    </Card>
  );
}

function DepartmentCards() {
  const { client, departments, setDepartments } = useClient();
  const { setDepartment, setEvaluations } = useDepartment();
  const [deletingId, setDeletingId] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [canAddDepartment, setCanAddDepartment] = useState(false);
  const { currentUserInfo } = useAuth();

  useEffect(() => {
    if (!currentUserInfo || !client) {
      return;
    }

    if (currentUserInfo.permissions === 'admin') {
      setCanAddDepartment(true);
    } else {
      const clientPermissions = currentUserInfo.permissions.clients[client.id];
      setCanAddDepartment(Object.keys(clientPermissions.departments).length === 0);

      if (Object.keys(clientPermissions.departments).length === 1) {
        navigate(`department/${Object.keys(clientPermissions.departments)[0]}`);
      }
    }
  }, [currentUserInfo, client, navigate]);

  useEffect(() => {
    setDepartment(null);
    setEvaluations(null);
    if (client && !departments) {
      const abortController = new AbortController();
      WorkloadAPI.getClientDepartments(client.id, abortController.signal).then(
        (result) => setDepartments(result),
        (reason) => {
          if (reason.name !== 'AbortError') {
            setError(reason);
          }
        },
      );
      return () => { abortController.abort(); };
    }
    return null;
  }, [setDepartment, setEvaluations, client, departments, setDepartments]);

  const onRemoved = useCallback((removedId) => {
    setDepartments((prev) => [...prev.filter((d) => d.id !== removedId)]);
  }, [setDepartments]);

  const onSelected = useCallback((dept) => {
    if (dept) {
      navigate(`department/${dept.id}`);
    }
  }, [navigate]);

  const onAddNewClick = useCallback(() => {
    navigate('department/new');
  }, [navigate]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <RemoveDepartmentWarning
        departmentId={deletingId}
        onHide={() => setDeletingId(null)}
        onRemoved={onRemoved}
      />
      <Row>
        <h3>Departments</h3>
      </Row>
      <Row>
        {!departments && <LoadingCards />}
        <Fade in={!!departments}>
          <div className="workspace-card-container">
            {canAddDepartment && (
              <Card className="workspace-card" onClick={onAddNewClick} onKeyPress={doOnKeyPress(onAddNewClick)} tabIndex={0}>
                <Card.Header>
                  <Card.Title>New department</Card.Title>
                </Card.Header>
                <Card.Body style={{ overflowY: 'auto', display: 'flex' }}>
                  <PlusCircleFill fill="#003255" fillOpacity={0.75} height={addNewSize} width={addNewSize} style={{ display: 'block', margin: 'auto' }} />
                </Card.Body>
              </Card>
            )}
            {departments && departments.map((department) => (
              <DepartmentCard
                key={department.id}
                department={department}
                disabled={deletingId === department.id}
                onCardClick={() => onSelected(department)}
                onDeleteClick={() => setDeletingId(department.id)}
              />
            ))}
          </div>
        </Fade>
      </Row>
    </>
  );
}

export default DepartmentCards;
