let handlers = [];

const registerToastHandler = (handler) => {
  handlers.push(handler);
};

const removeToastHandler = (handler) => {
  handlers = handlers.filter((h) => h !== handler);
};

const toast = (newToast) => {
  const toastData = {
    ...newToast,
    show: true,
  };
  handlers.forEach((h) => h(toastData));
};

const toastService = {
  registerToastHandler,
  removeToastHandler,
  toast,
};

export default toastService;
