export function openFileForDownload(data, filename) {
  const url = window.URL.createObjectURL(data);

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return url;
}

export function getFileNameFromHttpResponse(res) {
  const contentDispositionHeader = res.headers.get('Content-Disposition');
  if (!contentDispositionHeader) {
    return 'download';
  }
  const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
  return result.replace(/"/g, '');
}
