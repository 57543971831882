import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

import logo from '../logo_transparant.png';
import { isAdmin } from '../helper/oauth2';
import { useAuth } from '../providers/auth-provider';

function NavMenu() {
  const { currentUser, tryLogin, logout } = useAuth();

  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image src={logo} height={70} className="me-4" />
          Workloads
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav variant="pills" defaultActiveKey="/" className="me-auto">
            {currentUser && (
            <>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="workspace">Workspace</Nav.Link>
              {isAdmin(currentUser) && <Nav.Link as={Link} to="blueprints">Blueprints</Nav.Link>}
              {isAdmin(currentUser) && <Nav.Link as={Link} to="admin">Admin</Nav.Link>}
            </>
            )}
          </Nav>
          <Nav>
            {currentUser ? (
              <NavDropdown title={`Signed in as ${currentUser.email}`}>
                <NavDropdown.Item onClick={() => logout()}>Sign out</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link onClick={() => tryLogin()}>Sign in</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavMenu;
