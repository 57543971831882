import { frequencies } from './frequency';

export function roundMinute(n) {
  return Math.round(10 * n) / 10;
}

export function roundHour(n) {
  return Math.round(10 * n) / 10;
}

export function roundPercentage(n) {
  return Math.round(n);
}

export function roundPercentageDetailed(n) {
  return Math.round(100 * n) / 100;
}

export function roundEmployee(n) {
  return Math.round(100 * n) / 100;
}

export function formatEvaluation(evaluation) {
  let result = `${evaluation.data.department} - ${evaluation.data.evaluatedOn}`;
  if (evaluation.version > 0) {
    result += ` v${evaluation.version + 1}`;
  }
  return result;
}

export function formatFrequency(freq) {
  return frequencies.find((f) => f.value === freq).display;
}
