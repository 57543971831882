import { useEffect, useState } from 'react';
import {
  Button, Col, Container, Fade, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import bgImg from '../bedrijfsleven-achtergrond-1.jpg';
import { useAuth } from '../providers/auth-provider';

function Landing() {
  const { currentUser, tryLogin } = useAuth();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Container className="h-100">
      <Fade in={show} timeout={1000}>
        <div style={{ backgroundImage: `url(${bgImg})`, backgroundSize: 'cover' }} className="h-100">
          <div className="h-100 d-flex flex-column justify-content-center landing-overlay">
            <Row style={{ marginTop: '20%' }}>
              <Col md={{ span: 6, offset: 3 }}>
                {currentUser
                  ? <Button as={Link} to="/workspace" className="w-100" tabIndex={0}>Get started</Button>
                  : <Button className="w-100" onClick={tryLogin} tabIndex={0}>Sign in</Button>}
              </Col>
            </Row>
          </div>
        </div>
      </Fade>
    </Container>
  );
}

export default Landing;
