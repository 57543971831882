import Container from 'react-bootstrap/Container';
import { Route, Routes, Outlet } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import BlueprintCards from './blueprint-cards';
import BlueprintForm from './create-blueprint';
import EditBluePrint from './edit-blueprint';
import BlueprintStatus from './blueprint-breadcrumbs';

function Blueprintspace() {
  return (
    <Container className="pt-3">
      <Row>
        <BlueprintStatus />
      </Row>
      <Row>
        <Routes>
          <Route path="/" element={<BlueprintCards />} />
          <Route path="/new" element={<BlueprintForm />} />
          <Route path="/:blueprintId" element={<EditBluePrint />} />
        </Routes>
      </Row>
      <Outlet />
    </Container>
  );
}

export default Blueprintspace;
