import jwtDecode from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;
const APP_URL = process.env.REACT_APP_APP_URL;
const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const AUTH_CLIENT = process.env.REACT_APP_AUTH_CLIENT;

const scopes = [
  'openid', // The openid scope is required to get an id_token
  `${API_URL}/admin_read`,
  `${API_URL}/admin_write`,
  `${API_URL}/client_read`,
  `${API_URL}/client_write`,
];

export const config = {
  endpoints: {
    auth: `${AUTH_URL}/oauth2/authorize`,
    token: `${AUTH_URL}/oauth2/token`,
    logout: `${AUTH_URL}/logout`,
  },
  clientId: AUTH_CLIENT,
  redirectUrl: `${APP_URL}/auth`,
  logoutUrl: `${APP_URL}/logout`,
  scope: scopes.join(' '),
};

export function getUrlSearchParams(params) {
  const result = new URLSearchParams();
  Object.keys(params).forEach((paramName) => result.append(paramName, params[paramName]));
  return result;
}

export const tryLogin = () => {
  const state = Math.random().toString(36).substring(7);
  window.localStorage.setItem('state', state);
  const params = {
    response_type: 'code',
    client_id: config.clientId,
    redirect_uri: config.redirectUrl,
    state,
    scope: config.scope,
  };
  const destination = `${config.endpoints.auth}?${getUrlSearchParams(params).toString()}`;
  window.location = destination;
};

export const clearToken = () => {
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('id_token');
  window.localStorage.removeItem('refresh_token');
};

export const getAccessToken = () => window.localStorage.getItem('access_token');

export const getIDToken = () => window.localStorage.getItem('id_token');

export const getCurrentUser = (idToken) => {
  const decoded = jwtDecode(idToken);
  return decoded;
};

export const hasRole = (currentUser, role) => currentUser && currentUser['cognito:groups'] && currentUser['cognito:groups'].includes(role);

export const isAdmin = (currentUser) => hasRole(currentUser, 'admin');

export const getTokenFromRefresh = async () => {
  const token = window.localStorage.getItem('refresh_token');
  const params = {
    grant_type: 'refresh_token',
    client_id: config.clientId,
    // redirect_uri: config.redirectUrl,
    refresh_token: token,
    // code_verifier
  };

  const tokenResponse = await fetch(
    config.endpoints.token,
    {
      method: 'POST',
      headers: [
        ['Content-Type', 'application/x-www-form-urlencoded'],
      ],
      body: getUrlSearchParams(params),
    },
  );

  if (tokenResponse.status !== 200) {
    const msg = await tokenResponse.text();
    throw new Error(msg);
  }

  const tokenResult = await tokenResponse.json();
  window.localStorage.setItem('access_token', tokenResult.access_token);
  window.localStorage.setItem('id_token', tokenResult.id_token);
};
