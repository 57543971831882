import { useState, useEffect, useCallback } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {
  Navigate, Outlet, Route, Routes,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import toastService from '../toast-service';
import Admin from './admin';
import Auth from './auth';
import AuthGuard from './auth-guard';
import Landing from './landing';
import Logout from './logout';
import Workspace from './workspace';
import Blueprintspace from './blueprints/blueprint-routing';
import Error from './error';

function Main() {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((newToast) => {
    const t = { id: new Date().getTime(), ...newToast };
    setToasts((prev) => [...prev, t]);
  }, [setToasts]);

  const removeToast = useCallback((oldToastId) => {
    setToasts((prev) => prev.filter((t) => t.id !== oldToastId));
  }, [setToasts]);

  useEffect(() => {
    toastService.registerToastHandler(addToast);
    return () => { toastService.removeToastHandler(addToast); };
  }, [addToast]);

  return (
    <ErrorBoundary
      FallbackComponent={Error}
      // These are the kind of errors of which we might want to send a log somewhere.
      // onError={(error, info) => console.log(error, info)}
    >
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="auth" element={<Auth />} />
        <Route path="logout" element={<Logout />} />
        <Route path="workspace/*" element={<AuthGuard><Workspace /></AuthGuard>} />
        <Route path="blueprints/*" element={<AuthGuard roles={['admin']}><Blueprintspace /></AuthGuard>} />
        <Route path="admin" element={<AuthGuard roles={['admin']}><Admin /></AuthGuard>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Outlet />
      <div
        aria-live="polite"
        aria-atomic="true"
      >
        <ToastContainer className="toast-container" position="top-center">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              bg={toast.type}
              show={toast.show}
              onClose={() => removeToast(toast.id)}
              delay={3000}
              autohide
            >
              <Toast.Body>{toast.message}</Toast.Body>
            </Toast>
          ))}
        </ToastContainer>
      </div>
    </ErrorBoundary>
  );
}

export default Main;
