import { getWeeklySeconds } from './frequency';
import { getAverageObservedDuration, getFactor } from './observations';

export const defaultExpectationData = {
  expectations: [],
  totals: {
    weeklyExpectation: 0,
    observedTimeRatio: 0,
    observedUnitsRatio: 0,
    averageFactor: 0,
  },
};

export function getAverageFactor(expectations) {
  const relevantExpectations = expectations.filter((e) => !!e.factor);
  if (relevantExpectations.length === 0) {
    return 0;
  }
  const average = relevantExpectations
    .reduce((sum, e) => sum + e.factor, 0) / relevantExpectations.length;
  return average;
}

export function calcExpectationData(activityGroups, observations) {
  const activities = activityGroups.reduce((result, group) => [...result, ...group.activities], []);
  if (activities.length === 0) {
    return defaultExpectationData;
  }

  const expectations = activities.map((activity) => {
    const relevantObservations = observations.filter((o) => o.activityId === activity.id);
    const observedAverage = getAverageObservedDuration(relevantObservations);
    return {
      activity,
      observations: relevantObservations,
      observedAverage,
      weeklyObserved: getWeeklySeconds(activity.volume, activity.frequency, observedAverage),
      volume: {
        value: activity.volume,
        unit: activity.unit,
      },
    };
  });

  const manualExpectations = expectations.filter((e) => e.observations.length > 0);
  const calculatedExpectations = expectations.filter((e) => e.observations.length === 0);

  manualExpectations.forEach((e) => {
    if (!e.expectedSeconds) {
      e.expectedSeconds = e.activity.expectedSeconds || e.observedAverage;
    }
    e.factor = getFactor(e.activity);
  });

  const observedCount = expectations.filter((e) => e.observedAverage !== 0).length;
  const estimatedCount = expectations.filter((e) => e.activity.estimatedSeconds !== 0).length;
  const totals = {
    averageFactor: getAverageFactor(manualExpectations),
    observedCount,
    estimatedCount,
    observedUnitsRatio: estimatedCount ? observedCount / estimatedCount : 0,
    weeklyObserved: expectations.reduce((sum, e) => e.weeklyObserved + sum, 0),
  };

  calculatedExpectations.forEach((e) => {
    e.expectedSeconds = Math.round(totals.averageFactor * e.activity.estimatedSeconds);
  });

  totals.weeklyExpectation = expectations
    .reduce((result, current) => result + getWeeklySeconds(
      current.activity.volume,
      current.activity.frequency,
      current.expectedSeconds,
    ), 0);

  totals.observedTimeRatio = totals.weeklyExpectation
    ? totals.weeklyObserved / totals.weeklyExpectation
    : 0;

  return {
    expectations,
    totals,
  };
}
