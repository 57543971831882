import {
  createContext, useContext, useMemo, useState,
} from 'react';

export const ClientContext = createContext({
  client: null,
  setClient: () => {},
  departments: null,
  setDepartments: () => {},
});

export function ClientProvider({ client, children }) {
  const [currentClient, setCurrentClient] = useState(client);
  const [departments, setDepartments] = useState(null);

  const value = useMemo(() => ({
    client: currentClient, setClient: setCurrentClient, departments, setDepartments,
  }), [currentClient, setCurrentClient, departments, setDepartments]);

  return (
    <ClientContext.Provider value={value}>
      {children}
    </ClientContext.Provider>
  );
}

export const useClient = () => useContext(ClientContext);
