import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { CloudUpload } from 'react-bootstrap-icons';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import WorkloadAPI from '../../workload-api';
import toastService from '../../toast-service';

function ClientForm() {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (form.checkValidity()) {
      const data = {
        name,
      };
      setSaving(true);
      WorkloadAPI.createClient(data).then((result) => {
        toastService.toast({
          type: 'success',
          message: 'The client was created successfully.',
        });
        navigate(`../client/${result.id}`);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to create the client.',
        });
        setSaving(false);
      });
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="pt-3">
      <fieldset disabled={saving} className="flex-fill-limit d-flex flex-column">
        <Container>
          <Row>
            <h3>New client</h3>
          </Row>
          <Row xl="3">
            <Form.Group as={Col} className="mb-3" controlId="formClientName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="string"
                placeholder="Client name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a name.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="py-1" xl="6">
            <Col />
            <Col />
            <Col md="auto">
              <Button variant="primary" type="submit">
                {saving
                  ? (
                    <Spinner className="me-2" animation="border" role="status" variant="info" size="sm">
                      <span className="visually-hidden">Saving...</span>
                    </Spinner>
                  )
                  : <CloudUpload className="me-2" />}
                Create
              </Button>
            </Col>
          </Row>
        </Container>
      </fieldset>
    </Form>
  );
}

export default ClientForm;
