import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import { CloudUpload } from 'react-bootstrap-icons';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import Error from '../error';
import WorkloadAPI from '../../workload-api';
import toastService from '../../toast-service';
import Loading from '../loading';
import SpinnerIcon from '../spinner-icon';

const schema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false } }).default('name@example.com').allow(''),
});

function EditClientForm() {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const {
    register, handleSubmit, setValue, formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const { clientId } = useParams();

  useEffect(() => {
    async function loadClient() {
      const client = await WorkloadAPI.getClient(clientId);
      setValue('name', client.data.name, { shouldDirty: false });
      setValue('email', client.data.email, { shouldDirty: false });
      setLoading(false);
    }
    loadClient().then(() => {}, (reason) => setError(reason));
  }, [clientId, setValue]);

  const updateClient = (data) => {
    setValidated(true);

    if (isValid) {
      setSaving(true);
      WorkloadAPI.updateClient(clientId, data).then(() => {
        toastService.toast({
          type: 'success',
          message: 'The client was saved successfully.',
        });
        setValidated(false);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to save the client.',
        });
      }).then(() => setSaving(false));
    }
  };

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      {loading && <Loading />}
      <Fade in={!loading}>
        <Form noValidate validated={validated} onSubmit={handleSubmit((d) => updateClient(d))} className="flex-fill-limit d-flex flex-column pt-3">
          <fieldset disabled={saving} className="flex-fill-limit d-flex flex-column">
            <Container className="flex-fill-limit d-flex flex-column">
              <Row className="py-1" xl="3">
                <Col><h3>Client</h3></Col>
                <Col />
                <Col md="auto">
                  <Button variant="primary" type="submit" disabled={!(isDirty && isValid)}>
                    {saving ? <SpinnerIcon className="me-2" /> : <CloudUpload className="me-2" />}
                    <span>Save</span>
                  </Button>
                </Col>
              </Row>
              <Row xl="3">
                <Form.Group as={Col} className="mb-3" controlId="formClientName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" {...register('name')} isInvalid={!!errors.name} />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="formClientName">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" {...register('email')} isInvalid={!!errors.email} />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Container>
          </fieldset>
        </Form>
      </Fade>
    </>
  );
}

export default EditClientForm;
