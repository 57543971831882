export const frequencies = [
  { display: 'Day', value: 'day', factor: 5 },
  { display: 'Week', value: 'week', factor: 1 },
  { display: 'Two weeks', value: 'fortnight', factor: 0.5 },
  { display: 'Month', value: 'month', factor: 0.23 },
  { display: 'Quarter', value: 'quarter', factor: 0.08 },
  { display: 'Year', value: 'year', factor: 0.02 },
];

export function getWeeklySeconds(vol, freq, unitSeconds) {
  const frequency = frequencies.find((f) => f.value === freq);
  const weeklySeconds = frequency
    ? Math.round(vol * frequency.factor * unitSeconds)
    : NaN;
  return weeklySeconds;
}
