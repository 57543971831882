import {
  createContext, useContext, useState, useMemo,
} from 'react';

export const DepartmentContext = createContext({
  department: null,
  setDepartment: () => {},
  evaluations: null,
  setEvaluations: () => {},
});

export function DepartmentProvider({ department, children }) {
  const [currentDepartment, setCurrentDepartment] = useState(department);
  const [evaluations, setEvaluations] = useState(null);

  const value = useMemo(() => ({
    department: currentDepartment,
    setDepartment: setCurrentDepartment,
    evaluations,
    setEvaluations,
  }), [currentDepartment, setCurrentDepartment, evaluations, setEvaluations]);

  return (
    <DepartmentContext.Provider value={value}>
      {children}
    </DepartmentContext.Provider>
  );
}

export const useDepartment = () => useContext(DepartmentContext);
