import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { useTable } from 'react-table';
import {
  ThreeDots, XCircleFill, Lock, Unlock, SortAlphaDown, SortAlphaDownAlt,
} from 'react-bootstrap-icons';
import moment from 'moment';
import { Stack } from 'react-bootstrap';
import {
  DropdownCell,
  EditableCell,
  EditableCellDuration,
  IndexCell,
  ReadonlyDurationCell,
  SimpleButtonCell,
} from '../../../helper/table';
import { getObservedDuration } from '../../../helper/observations';

const defaultValues = {
  activityId: '',
  startTime: '',
  stopTime: '',
  totalSeconds: 0,
  lostSeconds: 0,
  volume: 1,
  remarks: '',
};

function ToggleEditCell(props) {
  const { editing, setEditing, row: { id } } = props;

  const isEditing = editing === id;

  const getIcon = () => (isEditing ? <Unlock /> : <Lock />);

  const toggleEditing = () => setEditing(isEditing ? null : id);

  return (
    <div style={{ marginTop: '6px', cursor: 'pointer' }} role="switch" aria-checked={isEditing} onClick={toggleEditing} onKeyPress={toggleEditing} tabIndex={0}>
      {getIcon()}
    </div>
  );
}

function ActivityHeader({ sortObservations }) {
  return (
    <Stack direction="horizontal" className="justify-content-between">
      <span>Activity</span>
      <Stack direction="horizontal" gap={1}>
        <Button onClick={() => sortObservations('down')}>
          <SortAlphaDown />
        </Button>
        <Button onClick={() => sortObservations('up')}>
          <SortAlphaDownAlt />
        </Button>
      </Stack>
    </Stack>
  );
}

function ActivityCell(props) {
  const { activityGroups } = props;
  return (
    <DropdownCell required {...props} style={{ width: '30em' }}>
      <option>Please select an activity.</option>
      {activityGroups.map((group) => (
        <optgroup label={group.name} key={group.id}>
          {group.activities.map((a) => <option key={a.id} value={a.id}>{a.name}</option>)}
        </optgroup>
      ))}
    </DropdownCell>
  );
}

function ActivityFooter({ addNewObservation }) {
  return (
    <div style={{ width: '3em', overflowX: 'visible' }}>
      <Button onClick={addNewObservation} style={{ width: 'max-content' }}>Add observation</Button>
    </div>
  );
}

function VolumeCell(props) {
  const {
    activityGroups,
    row: {
      index,
      original: { activityId },
    },
    rows: { length },
    column,
    addNewObservation,
  } = props;
  const activity = activityGroups
    .reduce((result, group) => [...result, ...group.activities], [])
    .find((a) => a.id === activityId);
  column.inputProps = {
    ...column.inputProps,
    type: 'number',
    style: { maxWidth: '50px' },
  };
  column.onKeyDown = (e) => {
    if (e.key === 'Tab' && index === length - 1) {
      addNewObservation();
      e.preventDefault();
    }
  };
  return (
    <InputGroup className="flex-nowrap">
      <EditableCell {...props} />
      <InputGroup.Text className="flex-grow-1">{activity ? activity.unit : ''}</InputGroup.Text>
    </InputGroup>
  );
}

function RemarksCell({ row, editModally }) {
  const onClick = () => editModally(
    '',
    'Remarks',
    'remarks',
    row.index,
    row.original.remarks,
  );

  return (
    <SimpleButtonCell variant="secondary" onClick={onClick}>
      <ThreeDots />
    </SimpleButtonCell>
  );
}

function RemoveButtonCell({ row: { index }, removeObservation }) {
  return (
    <SimpleButtonCell variant="danger" onClick={() => removeObservation(index)}>
      <XCircleFill />
    </SimpleButtonCell>
  );
}

function ObservationsTable(props) {
  const { value, onChange, activityGroups } = props;

  const [editing, setEditing] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [modalValue, setModalValue] = useState(null);

  const wasNewlyAdded = useRef(false);

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const getActivity = useCallback(
    (activityId) => activityGroups
      .map((ag) => ag.activities)
      .flat()
      .find((a) => a.id === activityId),
    [activityGroups],
  );

  const scrollDummy = useRef(null);

  useEffect(() => {
    if (wasNewlyAdded.current) {
      setEditing((value.length - 1).toString());
      scrollDummy.current.scrollIntoView();
      wasNewlyAdded.current = false;
    }
  }, [value]);

  const sortObservations = useCallback((sortMode) => {
    setEditing(null);
    emitChange([...value.sort((a, b) => {
      if (!a.activityId && !b.activityId) {
        return 0;
      }
      if (!a.activityId) {
        return 1;
      }
      if (!b.activityId) {
        return -1;
      }
      const nameA = getActivity(a.activityId)?.name || '';
      const nameB = getActivity(b.activityId)?.name || '';
      if (sortMode === 'up') {
        return nameB.localeCompare(nameA);
      }
      return nameA.localeCompare(nameB);
    })]);
  }, [value, emitChange, getActivity]);

  const updateMyData = useCallback((rowIndex, columnId, newValue) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true);

    emitChange(
      value.map((row, index) => {
        if (index === rowIndex) {
          const result = {
            ...row,
            [columnId]: newValue,
          };
          if (['startTime', 'stopTime'].includes(columnId)) {
            if (result.startTime && result.stopTime) {
              result.totalSeconds = moment(result.stopTime, 'HH:mm:ss').diff(moment(result.startTime, 'HH:mm:ss'), 's');
            } else {
              result.totalSeconds = '';
            }
          }
          if (columnId === 'totalSeconds' && row.totalSeconds !== result.totalSeconds) {
            result.startTime = '';
            result.stopTime = '';
          }
          return result;
        }
        return row;
      }),
    );
  }, [value, emitChange]);

  const addNewObservation = () => {
    emitChange([...value, { ...defaultValues }]);
    wasNewlyAdded.current = true;
  };

  const removeObservation = (i) => {
    emitChange([...value.slice(0, i), ...value.slice(i + 1)]);
    setEditing(null);
  };

  const editModally = (name, title, propName, rowIndex, initialValue) => {
    setModalProps({
      title,
      name,
      propName,
      rowIndex,
    });
    setModalValue(initialValue);
  };

  const onHideModal = () => {
    updateMyData(modalProps.rowIndex, modalProps.propName, modalValue);
    setModalProps(null);
    setModalValue(null);
  };

  const columns = useMemo(() => [
    {
      id: 'index',
      Header: '',
      Cell: IndexCell,
      rowStyle: { verticalAlign: 'middle' },
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ToggleEditCell,
      className: 'sticky-left',
      style: { background: 'white' },
    },
    {
      // Header: 'Activity',
      Header: ActivityHeader,
      accessor: 'activityId',
      Cell: ActivityCell,
      className: 'sticky-left',
      style: { background: 'white', left: '2em' },
      Footer: ActivityFooter,
    },
    {
      id: 'startTime',
      Header: 'Start',
      accessor: (row) => row.startTime || '',
      Cell: EditableCell,
      inputProps: { type: 'time', step: 1 },
    },
    {
      id: 'stopTime',
      Header: 'Stop',
      accessor: (row) => row.stopTime || '',
      Cell: EditableCell,
      inputProps: { type: 'time', step: 1 },
    },
    {
      Header: 'Duration',
      accessor: 'totalSeconds',
      Cell: EditableCellDuration,
    },
    {
      Header: 'Lost time',
      accessor: 'lostSeconds',
      Cell: EditableCellDuration,
    },
    {
      Header: 'Observed time',
      accessor: (observation) => getObservedDuration(observation) || 0,
      Cell: ReadonlyDurationCell,
    },
    {
      Header: 'Volume',
      accessor: 'volume',
      Cell: VolumeCell,
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
      Cell: RemarksCell,
    },
    {
      Header: '',
      id: 'actions',
      Cell: RemoveButtonCell,
    },
  ], []);

  const data = useMemo(() => value, [value]);

  const tableInstance = useTable({
    columns,
    data,
    updateMyData,
    editing,
    setEditing,
    activityGroups,
    sortObservations,
    addNewObservation,
    removeObservation,
    editModally,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="h-100">
      <Modal show={!!modalProps} onHide={onHideModal} centered size="lg">
        {!!modalProps && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalProps.name}
              {' '}
              {modalProps.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control as="textarea" rows={10} value={modalValue} onChange={(e) => setModalValue(e.target.value)} />
          </Modal.Body>
        </>
        )}
      </Modal>
      <Table striped borderless hover {...getTableProps()}>
        <thead className="sticky-top bg-primary">
          {// Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps({
                      className: [column.className, 'bg-primary align-middle'].join(' '),
                      style: { ...column.style },
                    })}
                    >
                      {// Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} style={{ height: '1rem' }}>
                  {// Loop over the rows cells
                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td {...cell.getCellProps({
                        className: cell.column.className,
                        style: { ...cell.column.style, ...cell.column.rowStyle },
                      })}
                      >
                        {// Render the cell contents
                          cell.render('Cell', { disabled: editing !== row.id })
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
          <tr id="scroll-dummy" ref={scrollDummy} />
        </tbody>
        <tfoot className="sticky-bottom bg-secondary">
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps({
                  className: [column.className, 'bg-secondary'].join(' '),
                  style: { ...column.style },
                })}
                >
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>
    </div>
  );
}

export default ObservationsTable;
