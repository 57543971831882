import { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { PencilFill } from 'react-bootstrap-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useClient } from '../providers/client-provider';
import { useDepartment } from '../providers/department-provider';
import { useEvaluation } from '../providers/evaluation-provider';
import ClientSidebar from './client-sidebar';
import { formatEvaluation } from '../helper/format';

function WorkspaceCrumb({ location }) {
  const workspacePath = '/workspace';

  if (location.pathname === workspacePath) {
    return <Breadcrumb.Item active>Workspace</Breadcrumb.Item>;
  }

  return (
    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: workspacePath }}>
      Workspace
    </Breadcrumb.Item>
  );
}

function ClientCrumb({ client, location }) {
  const clientPath = `/workspace/client/${client.id}`;

  if (location.pathname === clientPath) {
    return <Breadcrumb.Item active>{client.data.name}</Breadcrumb.Item>;
  }

  return (
    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: clientPath }}>
      {client.data.name}
    </Breadcrumb.Item>
  );
}

function DepartmentCrumb({ client, department, location }) {
  const deptPath = `/workspace/client/${client.id}/department/${department.id}`;

  if (location.pathname === deptPath) {
    return <Breadcrumb.Item active>{department.data.name}</Breadcrumb.Item>;
  }

  return (
    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: deptPath }}>
      {department.data.name}
    </Breadcrumb.Item>
  );
}

function WorkspaceStatus() {
  const [showSidebar, setShowSidebar] = useState(false);
  const { client } = useClient();
  const { department } = useDepartment();
  const { evaluation } = useEvaluation();
  const location = useLocation();

  return (
    <>
      <ClientSidebar show={showSidebar} onHide={() => setShowSidebar(false)} />
      <Stack direction="horizontal" gap={3}>
        <Breadcrumb>
          <WorkspaceCrumb location={location} />
          {client
            && <ClientCrumb client={client} location={location} />}
          {client && department
            && <DepartmentCrumb client={client} department={department} location={location} />}
          {evaluation
            && <Breadcrumb.Item active>{formatEvaluation(evaluation)}</Breadcrumb.Item>}
        </Breadcrumb>
        {client && department && (
        <Button className="ms-auto" variant="outline-secondary" onClick={() => setShowSidebar(true)} tabIndex={-1}>
          <PencilFill className="me-2" />
          Client details
        </Button>
        )}
      </Stack>
    </>
  );
}

export default WorkspaceStatus;
