import { PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  Button, Card, Container, Fade, Modal, Row, Col, Stack,
} from 'react-bootstrap';
import WorkloadAPI from '../../workload-api';
import Error from '../error';
import toastService from '../../toast-service';
import LoadingCards from '../loading-cards';
import SpinnerIcon from '../spinner-icon';
import Username from '../username';

export function BlueprintCard({
  blueprint, disabled, onCardClick, onDeleteClick,
}) {
  const onSelect = () => {
    if (disabled) {
      return;
    }
    onCardClick();
  };

  const onDelete = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    onDeleteClick();
  };

  const numActivityGroups = blueprint.data.activityGroups.length;
  const numActivities = blueprint.data.activityGroups
    .reduce((result, group) => result + group.activities.length, 0);

  const activitySummary = `${numActivities} activities in ${numActivityGroups} groups`;

  function getApprovedBy() {
    if (!blueprint.approvedBy) {
      return 'Not yet approved';
    }

    return (
      <>
        <span className="me-2">Approved by</span>
        <Username userId={blueprint.approvedBy} />
      </>
    );
  }

  return (
    <Card disabled={disabled} tabIndex={0} onClick={onSelect} className="workspace-card">
      <Card.Header as={Stack} direction="horizontal">
        <Card.Title>{blueprint.data.name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            {activitySummary}
          </Col>
        </Row>
        <Row>
          <Col>
            {getApprovedBy()}
          </Col>
        </Row>
      </Card.Body>
      {!!onDeleteClick && (
      <Card.Footer>
        <Button variant="danger" disabled={disabled} onClick={onDelete}>
          <XCircleFill className="me-2" />
          Delete
        </Button>
      </Card.Footer>
      )}
    </Card>
  );
}

function RemoveBlueprintWarning({
  blueprintId, onHide, onRemoved,
}) {
  const [deleting, setDeleting] = useState(false);

  const deleteSelected = useCallback(() => {
    setDeleting(true);
    WorkloadAPI.deleteBlueprint(blueprintId).then(
      () => {
        toastService.toast({
          type: 'success',
          message: 'The blueprint was deleted successfully.',
        });
        onRemoved(blueprintId);
        onHide();
      },
      () => {
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to delete this blueprint.',
        });
      },
    ).finally(() => {
      setDeleting(false);
    });
  }, [blueprintId, onRemoved, onHide]);

  return (
    <Modal show={!!blueprintId} onHide={onHide} backdrop={deleting ? 'static' : true}>
      <Modal.Header closeButton={!deleting}>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Removing a blueprint will remove all the data corresponding to it!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={deleting}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteSelected} disabled={deleting}>
          {deleting ? <SpinnerIcon className="me-2" /> : <XCircleFill className="me-2" />}
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const addNewSize = '7.5em';

function BlueprintCards() {
  const navigate = useNavigate();
  const [blueprints, setBlueprints] = useState(null);
  const [error, setError] = useState(null);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    WorkloadAPI.getBlueprints(abortController.signal).then(
      (result) => setBlueprints(result),
      (reason) => {
        if (reason.name !== 'AbortError') {
          setError(reason);
        }
      },
    );
    return () => { abortController.abort(); };
  }, []);

  const addNewBlueprint = useCallback(() => {
    navigate('/blueprints/new');
  }, [navigate]);

  const onCardClick = useCallback((blueprint) => {
    navigate(`/blueprints/${blueprint.id}`);
  }, [navigate]);

  const onRemoved = useCallback((removedId) => {
    setBlueprints((prev) => [...prev.filter((d) => d.id !== removedId)]);
  }, [setBlueprints]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Container>
      <RemoveBlueprintWarning
        blueprintId={deletingId}
        onHide={() => setDeletingId(null)}
        onRemoved={onRemoved}
      />
      <Row>
        <h3>Blueprints</h3>
      </Row>
      <Row>
        {!blueprints && <LoadingCards />}
        <Fade in={!!blueprints}>
          <div className="workspace-card-container">
            <Card tabIndex={0} onClick={addNewBlueprint} className="workspace-card">
              <Card.Header>
                <Card.Title>New Blueprint</Card.Title>
              </Card.Header>
              <Card.Body style={{ overflowY: 'auto', display: 'flex' }}>
                <PlusCircleFill fill="#003255" fillOpacity={0.75} height={addNewSize} width={addNewSize} style={{ display: 'block', margin: 'auto' }} />
              </Card.Body>
            </Card>
            {!!blueprints && blueprints.map((blueprint) => (
              <BlueprintCard
                key={blueprint.id}
                blueprint={blueprint}
                disabled={deletingId === blueprint.id}
                onCardClick={() => onCardClick(blueprint)}
                onDeleteClick={() => setDeletingId(blueprint.id)}
              />
            ))}
          </div>
        </Fade>
      </Row>
    </Container>
  );
}
export default BlueprintCards;
