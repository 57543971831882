import { useEffect, useState } from 'react';
import {
  Route, Routes, Outlet, useParams, Navigate,
} from 'react-router-dom';

import WorkloadAPI from '../workload-api';
import Error from './error';
import { useClient } from '../providers/client-provider';
import DepartmentCards from './department-cards';
import DepartmentForm from './department/create-department';
import WorkspaceDepartment from './workspace-department';
import EditClientForm from './client/edit-client';

function WorkspaceClient() {
  const { clientId } = useParams();
  const { setClient } = useClient();
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    WorkloadAPI.getClient(clientId, abortController.signal).then(
      (result) => setClient(result),
      (reason) => {
        if (reason.name !== 'AbortError') {
          setError(reason);
        }
      },
    );
    return () => { abortController.abort(); };
  }, [clientId, setClient]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<DepartmentCards />} />
        <Route path="edit" element={<EditClientForm />} />
        <Route path="department/new" element={<DepartmentForm />} />
        <Route path="department/:departmentId/*" element={<WorkspaceDepartment />} />
        <Route path="*" element={<Navigate to="./" replace />} />
      </Routes>
      <Outlet />
    </>
  );
}

export default WorkspaceClient;
