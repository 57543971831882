import Spinner from 'react-bootstrap/Spinner';

function Loading() {
  return (
    <Spinner animation="grow" role="status" style={{ display: 'block', margin: 'auto' }}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Loading;
