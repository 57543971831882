import { Offcanvas, Spinner, Stack } from 'react-bootstrap';
import { CaretRight, Dot } from 'react-bootstrap-icons';
import { NavLink, useParams } from 'react-router-dom';
import { formatEvaluation } from '../helper/format';
import { isAdmin } from '../helper/oauth2';
import { useAuth } from '../providers/auth-provider';
import { useClient } from '../providers/client-provider';
import { useDepartment } from '../providers/department-provider';

function EvaluationLink({
  client, department, evaluation, isActive,
}) {
  return (
    <NavLink to={`client/${client.id}/department/${department.id}/evaluation/${evaluation.id}`} className={isActive ? 'link-secondary' : 'link-primary'}>
      {isActive ? <CaretRight /> : <Dot />}
      {formatEvaluation(evaluation)}
    </NavLink>
  );
}

function LoadingQuickSwitch() {
  return (
    <Spinner animation="grow" role="status" style={{ display: 'block', margin: 'auto' }}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

function ClientSidebar({ show, onHide }) {
  const { client } = useClient();
  const { department, evaluations } = useDepartment();
  const { currentUser } = useAuth();

  const params = useParams();

  if (!client || !department) {
    return null;
  }

  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {client.data.name}
          {' - '}
          {department.data.name}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <hr />
      <Offcanvas.Body>
        <Stack gap={3}>
          {isAdmin(currentUser) && <NavLink to={`client/${client.id}/edit`}>Edit client</NavLink>}
          {isAdmin(currentUser) && <NavLink to={`client/${client.id}/department/${department.id}/edit`}>Edit department</NavLink>}
          {evaluations ? (
            <div>
              <div>Quick switch</div>
              <div className="d-flex flex-column">
                {evaluations.map((e) => <EvaluationLink key={e.id} client={client} department={department} evaluation={e} isActive={params['*'].includes(e.id)} />)}
              </div>
            </div>
          ) : <LoadingQuickSwitch />}
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ClientSidebar;
