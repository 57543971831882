import { useEffect, useState } from 'react';
import {
  Button, Col, Container, Fade, Row,
} from 'react-bootstrap';
import { useAuth } from '../providers/auth-provider';

function Logout() {
  const { tryLogin } = useAuth();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Container className="h-100">
      <Fade in={show} timeout={1000}>
        <div className="h-100">
          <div className="h-100 d-flex flex-column justify-content-center">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div>You have been signed out successfully.</div>
                <Button className="w-100" onClick={tryLogin}>Sign back in</Button>
              </Col>
            </Row>
          </div>
        </div>
      </Fade>
    </Container>
  );
}

export default Logout;
